.store_locator {

    .breadcrumbs {
        padding-left: 0;
    }

    .storePic {
        display: none;
    }

    .main_wrapper {
        max-width: $wrapperMediumXL;
        width: 100%;
        margin: 0 auto;

        @include mediumxlScreen {
            padding: 0 $min_pad;
        }
    }

    .formWrapper h1.title {
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 23px;
        line-height: 28px;
        text-transform: uppercase;
        color: $primaryColor;
        padding-top: 1rem;
    }

    nav.breadcrumbs {
        padding-top: .5rem;
        margin-bottom: 40px;
    }

    .storeloc_form {
        margin-bottom: 3.813rem;

        .wrap_fields {
            display: flex;
            align-items: center;
        }

        .field_country {
            width: 5.208vw;
            min-width: 100px;
            margin-right: 1rem;
            height: 3rem;

            .w-input.w-dropdown {
                height: 48px;
                border: solid 1px #898989;
            }

            #search_pays {
                width: 100%;
                box-sizing: border-box;
            }
        }

        .div_search_cp {
            width: 350px;
            height: 48px;
            margin-right: 21px;

            input {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 0 0.85714em
            }
        }

        .field_address {
            width: 18.229vw;
            min-width: 350px;
            margin-right: 1.313rem;

            #search_cp {
                width: 100%;
                height: 3rem;
                box-sizing: border-box;
                padding-left: 16px;
                border-radius: 0;
                border: solid 1px #898989;
            }
        }

        .ou {
            margin-right: 1.25rem;
            display: flex;
            align-items: center;
            font-family: $secondaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: $small_txt_lh;
            text-transform: uppercase;
            color: $primaryColor;
        }

        .loc_btn {
            @extend .button;
            width: 10.417vw;
            min-width: 200px;
            height: 2.5rem;
            background-color: black;
            color: white;
            border: none;
            box-sizing: border-box;
            padding: 0;

            &:hover {
                background-color: $white;
                color: $primaryColor;
                padding: 0;

                span {
                    background-color: $white;
                    color: $primaryColor;
                    border: solid 1px black;
                }
            }
        }
    }

    .wrapper_retailers_map {
        display: flex;

        .resultat_recherche {
            display: none !important;
        }

        .liste_recherche_distributeur {
            width: 32vw;
        }

        #liste_distributeur {
            opacity: 0;
            transition: opacity .3s ease-in-out;

            &.appear {
                opacity: 1;
            }
        }

        .os-content {
            display: flex;
            flex-wrap: wrap;
            max-height: 44.625rem;
            min-width: 32vw;

            .elem_liste_distributeur {
                width: 13.385vw;
                min-width: 11.927vw;
                margin: 0 40px 5rem 0;

                .nom_store {
                    font-family: $secondaryFont;
                    font-weight: $medium;
                    font-size: 13px;
                    line-height: $small_txt_lh;
                    text-transform: uppercase;
                    color: $primaryColor;
                    margin-bottom: 1rem;
                }

                .rue_store, .phone_store, .horaire_today, .email_store {
                    font-family: $primaryFont;
                    font-weight: $medium;
                    font-size: 13px;
                    line-height: 26px;
                    text-transform: uppercase;
                    color: $primaryColor;
                }

                .email_store {
                    text-transform: uppercase;
                    width: max-content;
                }

                .store_open {
                    display: none;
                }

                .store_more {
                    font-family: $secondaryFont;
                    font-weight: $medium;
                    font-size: 11px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    color: $primaryColor;
                    margin-top: 1.5rem;
                    border-bottom: solid 1px black;
                    width: 74px;

                    a::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }

        .os-theme-dark>.os-scrollbar>.os-scrollbar-track {
            background-color: #CED2D8;
        }

        .os-scrollbar-handle {
            height: 80px !important;
            background-color: $primaryColor !important;
        }

        #retailers_map {
            margin-left: 2.708vw;
            max-width: 46.719vw;
            height: 46.938rem;

            .cta_row {
                display: flex;
                width: 100%;
                gap: 8px;

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

// Storelocator détails

.satellite_store_details {

    #breadcrumbs {
        margin-bottom: 2.5rem;

        .breadcrumbs {
            padding-top: 9px;
        }
    }

    #site_global_wrap {
        margin: calc(40px + 100px) auto;
        max-width: 1720px;
        margin-bottom: 100px;
    }

    .locator_heading  {
        max-width: 1720px;
        box-sizing: border-box;
        padding-top: 1rem;

        @media (max-width: 1720px) {
            padding: 0 32px;
        }
    }

    .locator_heading .title span {
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 23px;
        line-height: 28px;
        text-transform: uppercase;
        color: $primaryColor;
    }

    .wrapper_moncompte.masgasin_pages {
        margin: 0;
        max-width: 1580px;
        justify-content: flex-start;

        @media screen  and (max-width: 1440px) {
            width:100%;
        }
    }

    .return-link {

        span {
            display: flex;
            align-items: center;
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: $small_txt_lh;
            color: $primaryColor;
        }

        ::before {
            content: "";
            margin: 0 9.6px 0 0;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(1, 6, 6);
            width: 10px;
            height: 10px;
            display: block;
            transform: rotate(90deg);
        }
    }

    .mian_div {
        width: 17.4%;
        margin-right: 2%;
        max-width: 300px;

        .title {
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: $primaryColor;
            margin-top: 16px;
        }

        .storeAbout {
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 13px;
            color: $primaryColor;
            margin-top: 8px;
        }

        .storeAddress {
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: 30px;
            text-transform: uppercase;
            color: $primaryColor;
            margin-top: 24px;
            display: flex;
            flex-direction: column;
        }

        .store_adresse_2 {
            display: inline-block;
        }

        .store_phone {
            order: 0;
        }

        .store_email {
            order: 1;
        }

        .store_open {
            display: none;
        }

        .storeInfos {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
        }

        .holiday {
            font-size: 12px;
            margin-bottom: 1rem;
            order: 1;
            width: max-content;
        }

        .storeHours {
            margin-bottom: 1rem;

            .label {
                font-family: $secondaryFont;
                font-weight: $medium;
                font-size: 13px;
                line-height: $small_txt_lh;
                color: $primaryColor;
                margin-bottom: 1rem;
            }

            .time_table {
                font-family: $primaryFont;
                font-weight: $medium;
                font-size: 13px;
                line-height: $small_txt_lh;
                text-align: left;
                color: #000000;
                width: max-content;
            }

            .block_day_hours {
                display: flex;
                justify-content: space-between;
                margin-bottom: .5rem;

                span {
                    min-width: 120px;
                }

                .day {
                    min-width: 127px
                }
            }
        }
    }

    .form_submit {
        width: 16.88%;
        min-width: 245px;
        margin: 3.25rem 0 0 0;

        .btn_tunnel {
            margin-bottom: 1rem;
            background-color: $black;

            &:hover {
                background-color:$white;
                color: $black;

                span {
                    background-color:$white;
                    color: $black;
                }
            }

            span {
                background-color: unset;
                color: $white;
            }
        }

        .btn_blue {
            text-align: center;
            line-height: 40px;
        }
    }

    // MAP //
    #store_detail_map {
        width: 100%;
        height: 32.438rem;
    }

    .gmap-img-wrapper {
        max-width: 850px;
        width: 55%;
        margin-left: 8%;
    }

    //
    #ligthbox_send_store_information {
        width: 600px;

        &.lightbox, .lightbox.actif {
            transition: none;
        }

        .close_pop.close {
            padding : 30px;
        }

        .close_pop.close::before {
            content: "";
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(2, 6, 6);
            width: 15px;
            height: 15px;
            display: block;
        }

        .popup_title {
            margin-bottom: 1rem;
        }

        .w-form-line.w-submit {
            margin: auto;
            width: 100px;
            max-width: none;
        }

        .w-sendshop-form p:not(.w-input-label) {
            margin: 1rem 0;
        }
    }

    // img magasin

    .store_img {
        width: 1543px;
        max-width: 97%;
        margin-top: 24px;
        position: relative;

        @media screen and (max-width: 850px) {
            max-width: 96%;
        }

        @media screen and (max-width: 1720px) {
            padding-left: 2rem;
        }

        .swiper-wrapper {
            justify-content: flex-start;
        }

        .swiper-slide {
           max-width: fit-content;
        }

        .swiper-button-prev {
            left: -2%;
        }

        .swiper-button-next {
            right: -2%;
        }
    }
}

.en.store_locator .store_more {
    width: 88px !important;
}
