#scroll_items {
    max-width: $wrapperMediumXL;
    margin: 0 auto;
    overflow: hidden;

    @include mediumxlScreen {
        padding: 0 $min_pad;
    }
}

#list_item {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;

    a.block_lnk {
        max-width: $img_rayon;
    }

    .item.wrapperProdVertical {
        width: calc(100% / 4);
        padding: 0 12px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }

    .no-result {
        z-index: 1;
        margin:10px 0 30px 12px;
        font-size: 1em;
        text-transform: uppercase;
        font-weight: 500;
        font-family: $secondaryFont;
    }
}

.category {

    .breadcrumbs {
        padding-top: 11px;
        margin-bottom: 16px;
    }
}


/* PUSHS SECTIONS */
.section_push {
    display: flex;
    max-width: $wrapperMediumXL;
    width: 100%;
    margin: 0 auto;

    @include mediumxlScreen {
        padding: 0 $min_pad;
    }

    &_side {
        &_left {
            max-width: 1140px;
            width: 66.3%;

            img {
                max-width: 100%;
            }
        }
        &_right {
            max-width: 580px;
            width: 33.7%;
            padding-left: pxToVw(80,1920);
        }
    }

    &_top {
        align-items: center;
        margin-bottom: 24px;
    }
    &_bottom {
        position: relative;
        align-items: flex-end;
        padding-top: pxToVw(80, 1920);
        margin-top: pxToVw(120, 1920);

        &::before {
            content: "";
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $primaryColor;
        }

        &::before {
            top: 0;
        }

        @include mediumxlScreen {
            padding: 40px $min_pad 40px;

            &::before {
                left: $min_pad;
                width: calc(100% - (2 * #{$min_pad}));
            }
        }

        .section_push_side_right {
            padding-bottom: pxToVw(199, 1920);

            @media screen and (max-width: 1600px) {
                padding-bottom: pxToVw(139, 1920);
            }

            @media screen and (max-width: 1400px) {
                padding-bottom: pxToVw(109, 1920);
            }

            @include smallScreen {
                padding-bottom: pxToVw(89, 1920);
            }

            .content_logo {
                max-width: pxToVw(100, 1280);
            }
        }

    }

    .content {
        &_logo {
            display: block;

            img {
                max-width: 100%;
            }
        }
        &_text {
            font-size: $small_txt;
            line-height: 18px;
            margin-top: pxToVw(40, 1920);

            @include smallScreen {
                font-size: 11px;
            }
        }
        &_nav {
            display: flex;
            max-width: 100%;
            padding-top: pxToVw(122,1920);
            margin: 0 -24px;

            @media screen and (max-width: 1600px) {
                padding-top: pxToVw(62, 1920);
            }

            @media screen and (max-width: 1400px) {
                padding-top: pxToVw(42, 1920);
            }

            @include smallScreen {
                padding-top: pxToVw(22, 1920);
            }

            .nav_cta {
                display: block;
                font-family: $secondaryFont;
                font-size: $small_txt;
                text-transform: uppercase;
                line-height: $small_txt_lh;
                padding-bottom: 4px;
                padding: pxToVw(12, 1920) pxToVw(24, 1920) 0;

                &:hover {
                    >span::after {
                        left: unset;
                        right: 0;
                        width: 0;
                    }
                }

                >span {
                    position: relative;
                    padding-bottom: 4px;

                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background-color: $primaryColor;
                        transition: $all_transition;
                    }
                }
            }
        }
    }
}


/* BANDEAU */
.wrapper_bandeau.rayon .title {
    max-width: $wrapperMediumXL;
    margin: 0 auto;

    @include mediumxlScreen {
        padding: 0 2rem;
    }
}

.wrapper_bandeau.rayon {
    .title {
        font-family: $secondaryFont;
        font-size: 13px;
        font-weight: $medium;
        text-transform: uppercase;
        line-height: 27px;
    }
}


/* RAYON FILTERS */
#rayon_filters {
    position: relative;
    max-width: $wrapperLarge;

    .filters_wrapper {
        z-index: 5;
        position: fixed;
        top: 0;
        right: 0;
    }
}
#search_filters {
    position: relative;
    max-width: $wrapperLarge;
    z-index: 12;

    .filters_wrapper {
        position: relative;
        z-index: 5;
    }

    #filter_sticky {
        padding: 0;

        &.isOpen {
           z-index: 12;
           position: fixed;
        }
        
        .filters_container {
            position: fixed;
            top: 0;
            right: 0;
        }
    }
}

.trigger_filtre_container {
    position: relative;
    max-width: $wrapperMediumXL;
    margin: 0 auto;

    @include mediumxlScreen {
        padding: 0 $min_pad;
    }
}

#trigger_filtre {
    position: absolute;
    bottom: calc(100% + 16px);
    right: 0;
    display: inline-block;
    cursor: pointer;
    font-family: $secondaryFont;
    font-size: $small_txt;
    line-height: 15px;
    text-transform: uppercase;
    padding: 1rem 0 0 1rem;

    @include mediumxlScreen {
        right: 2rem;
    }

    &.active {
        &::after {
            content: "-";
        }
    }

    &::after {
        content: "+";
        display: inline-block;
        min-width: 10px;
        width: auto;
        font-family: $secondaryFont;
        font-size: 15px;
        text-align: right;
        line-height: 15px;
        margin-left: 6px;
    }
}


// SCROLLBAR SETUP
#filter_sticky .filters_container,
#filter_sticky .filters_container_wrapper,
#filter_sticky .scrollable,
#filter_sticky .scroll_container,
#filter_sticky .selected_options {
    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $black;
    }
    
    &::-webkit-scrollbar-track {
        background-color: rgba($grey, 0.5);
    }
}

#filter_sticky {
    position: absolute;
    max-width: $wrapperLarge;
    width: 100%;
    background-color: $white;
    opacity: 0;
    padding-bottom: 80px;
    display: none;

    &.isOpen {
        display: block;
        opacity: 1;

        .filters_wrapper {
            z-index: 12;
        }
    }

    .filters_container {
        position: relative;
        display: grid;
        grid-template-rows: auto auto 1fr;
        justify-items: stretch;
        align-items: stretch;
        margin: 0 auto;
        width: 440px;
        height: 100vh;
        z-index: -1;
        background-color: $white;
        transition: $all_transition;
        padding: 20px 10px 20px 30px;

        .close_filter {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 20px;
            height: 20px;
            padding: 12px 0;
            margin-top: 15px;
            position: absolute;
            top: 15px;
            right: 28px;
            text-align: center;
            font-size: 20px;
            line-height: 20px;
            cursor: pointer;
            transition: $all_transition;
            font-weight: 900;

            
            &:before {
                content: "X";
            }

        }

        .title_filter {
            font-family: $secondaryFont;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            font-weight: 500;
            padding: 10px 0 0;
        }
    }

    .holder_submits {
        margin-top: auto;
        padding: 26px 20px 0 0;
        .form_submit:not(:last-child) {
            margin-bottom: 16px;
        }

        // add custom loader when the buttons are loading
        .button.loading {
            position: relative;
            overflow: hidden;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $white;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../svg/three_dots.svg);
                background-size: 18%;
                width: 100%;
                height: 100%;
            }
            
            &.dark::after {
                background-color: $black;
                background-image: url(../svg/three_dots_w.svg);
            }
        }
    }

    &.isOpen .filters_container_wrapper {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        scrollbar-gutter: stable;
        overflow-x: clip;
        padding: 0 20px 0 0px;
    }

    .filter_wrapper {
        padding: 23px 0;
        border-bottom: 1px solid $black;

        &#filter_trier {
            order: 1;
        }

        &#filter_color {
            order: 2;

            .colorOptions {
                max-height: calc(32px * 7); // 7 colors max, 16px line-height + 16px margin-bottom 
                overflow-y: auto;
            }
        }

        &#filter_sizes {
            order: 3;

            .sizeOptions {
                max-height: calc(32px * 7); // 7 sizes max, 16px line-height + 16px margin-bottom 
                overflow-y: auto;
            }
        }

        // categories
        &[data-id="167"] {
            order: 4;
        }

        // cut
        &[data-id="140"] {
            order: 5;
        }

        // material
        &[data-id="159"] {
            order: 6;
        }

        // collar type
        &[data-id="150"] {
            order: 7;
        }

        // sleeve type
        &[data-id="154"] {
            order: 8;
        }
    }

    .filter_name {
        font-family: $secondaryFont;
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        margin-bottom: 0px;
        user-select: none;
        cursor: pointer;
        transition: $all_transition;
        
        &:after {
            content: "";
            display: block;
            border-bottom: 1px solid $black;
            border-right: 1px solid $black;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            transition: $all_transition;
        }

        &.active {
            margin-bottom: 16px;

            &:after {
                transform: rotate(-135deg);
            }
        }
    }

    .scroll_container {
        display: none;
    }

    .selected_options {
        > .form_itm {
            font-size: $small_txt;
            line-height: $small_txt_lh;
            text-transform: uppercase;
            font-weight: $medium;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            input {
                display: none;
            }
        }
        .tri_price,
        .form_itm label {
            cursor: pointer;
            padding-bottom: 2px;
            @extend .to_underline;
        }

        &.colorOptions {
            .form_itm {
                width: fit-content;

                label {
                    display: flex;
                    align-items: center;

                    // if there's a data-color attribute, we set the color of the circle to the value of the attribute
                    &[data-color] {
                        --color: attr(data-color);
                    }
                        
                    &:before {
                        content: "";
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        margin-right: 6px;
                        border: 1px solid $black;
                        border-radius: 50%;
                        background-color: var(--color, $white);
                        transition: $all_transition;
                    }
                }
            }
        }

        &.triOptions {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            #slider-range {
                width: calc(100% - 34px); // width - wanted margin-right
                margin-left: 5px;
                margin-top: 16px;
                margin-bottom: 23px + 5px; // price label height + true margin-bottom
                height: 2px;
                // bg color needs to be $grey with a 10% opacity
                background-color: rgba($grey, 0.1);
            }
        }

        .tri_price.actif,
        .form_itm input:checked+label {
            &::after {
                right: auto;
                left: 0;
                width: 100%;
            }
        }
    }
    .filter_link_redirect {
        display: block;
    }

    // Vue slider
    .slider_wrapper {
        width: calc(100% - 34px); // width - wanted margin-right
        margin-left: 12px;
        margin-top: 30px;
        margin-bottom: 21px;
    }
}

/* PAGINATION */

.load_wrapper {
    max-width: 146px;
    margin: 0 auto;

    .loader_scroll {
        position: relative;
    }

    @include mediumxlScreen {
        box-sizing: content-box;
        padding: 0 2rem;
    }
}

.category .rollover_left.achat_express {
    min-height: 88px;

    .productSizeFieldset {
        padding-top: 7px
    }
}

.category {
    .breadcrumbs .breadcrumb {
        a, span {
            font-size: 12px;
        }
    }
}