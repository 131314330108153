$col: 6;
$row: 6;
$spritesheet: '../svg/spritesheet.svg';

/*******************************************************************/
/* HEADER LOGOS */
/*******************************************************************/

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.bfr {
        &:before {
            content: '';
            width: 15px;
            height: 15px;
        }
    }

    &.aft {
        &:after {
            content: '';
            width: 15px;
            height: 15px;
        }
    }

    @include spriter('../svg/spritesheet.svg', $col, $row, (
        1: 'arrow',
        2: 'cross',
        3: 'account',
        4: 'cart',
        5: 'locator',
        6: 'search',
        7: 'wishlist',
        8: 'wishlistFull',
        9: 'burgerMenu',
    ));
}