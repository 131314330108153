@import '../../_app/_src/scss/desktop/mixins/typeFace';

$chaletlondonnineteensixty: (
    normal: (regular)
);
$chaletnewyorknineteensixty: (
    normal: (regular)
);

@include typeFace('chaletnewyorknineteensixty', $chaletnewyorknineteensixty);
@include typeFace('chaletlondonnineteensixty', $chaletlondonnineteensixty);

/* FONTS */
$primaryFont: 'chaletlondonnineteensixty', sans-serif;
$secondaryFont: 'chaletnewyorknineteensixty', sans-serif;

//FONT-WEIGHT SETUP - OUTFIT FONT
$thin : 100;
$extralight : 200;
$light : 300;
$regular : 400;
$medium : 500;
$semibold : 600;
$bold : 700;
$extrabold : 800;
$blackfont : 900;