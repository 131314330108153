.faq {
    .title_container_contact {
        display: flex;
        max-width: 1720px;
        margin: 0 auto;
        padding: 16px 0 8px 0;

        .titre_contact {
            font-family: $secondaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 27px;
            text-transform: uppercase;
        }
    }

    .breadcrumbs {
        padding-top: 0;
    }

    .wrapper_moncompte {
        display: flex;
        max-width: 1720px;
        margin: 41px auto 0 auto;

        .w-submit {
            max-width: max-content;

            .w-submit-button {
                padding: 0 121px;
            }
        }

        .wrapper_moncompte_main {
            display: flex;
            max-width: 1370px;

            .w-radio-input {
                span {
                    color: $primaryColor;
                }
            }

            .faq_content {
                .theme_global {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    row-gap: 25px;
                    column-gap: 24px;

                    .theme {
                        margin: 0;
                        padding: 0 1vmin 0;
                        text-align: center;
                        border: 1px solid $black;
                        width: 13.8542vw;
                        background-repeat: no-repeat;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        height: 14.5811vh;
                        justify-content: center;
                        align-items: center;

                        &.active {
                            border: 0 none;
                            background-color: lightgray;

                            h2 {
                                text-align: center;
                                font-family: $secondaryFont;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-transform: uppercase;
                            }
                        }

                        .faq_background_image {
                            height: 36px;
                            width: 36px;
                            margin-bottom: 10px;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                        }

                        h2 {
                            text-align: center;
                            font-family: $primaryFont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                        }

                        @media screen and (max-width: 680px) {
                            h2 {
                                font-size: 0.4375em;
                            }
                        }
                    }
                }

                .theme_page {
                    background-color: transparent;
                    margin-left: 6.875rem;

                    .theme_detail {
                        margin-top: 50px;

                        .faq_title {
                            display: none;
                            font-weight: bold;
                            padding: 32px 32px 5px 6px;
                            font-size: 13px;
                        }

                        .faq_questions {
                            display: none;
                        }

                        .faq_question {
                            padding: 22px 32px 18px 10px;
                            display: flex;
                            justify-content: space-between;
                            border-bottom: 1px solid $grey;
                            box-sizing: border-box;
                            cursor: pointer;
                            font-size: 13px;

                            h3 {
                                font-family: $primaryFont;
                                font-weight: 400;
                            }

                            &:first-child {
                                width: 100%;
                            }

                            &::after {
                                transition: transform .3s ease-out;
                                content: "";
                                display: block;
                                width: 14px;
                                height: 14px;
                                background-image: url(../svg/spritesheet1.svg);
                                background-size: 500%;
                                background-position: 100% 0%;
                                background-repeat: no-repeat;
                                transform: rotate(90deg);
                            }

                            &.active {
                                border-bottom: 0 none;

                                &:after {
                                    transform: rotate(270deg);
                                }
                            }
                        }

                        .faq_reponse {
                            display: none;
                            border-bottom: 1px solid $grey;
                            padding-left: 30px;
                            padding-bottom: 13px;
                            padding-top: 13px;
                            font-weight: 500;
                            font-size: 13px;
                            font-family: $primaryFont;
                            line-height: 15.76px;
                        }
                    }

                    .theme_menu {
                        display: none;
                        padding: 32px 32px 35px 29px;

                        span {
                            font-size: 13px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .form_submit.w-submit {
            margin: 80px 0 100px 0;

            .w-submit-button {
                width: max-content;
                display: block;

                span {
                    font-family: $secondaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: $small_txt_lh;
                }
            }
        }

        .flexAccount {
            .wrapper_menu_compte_wrapper {
                background-color: cadetblue;
                display: none;
            }

            .site_search {
                .search_results {
                    padding: 5px;
                    display: none;

                    .brand_item {
                        padding: 10px 0px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 1.063rem;
                        border-bottom: 1px solid $grey;
                        box-sizing: border-box;
                        font-weight: 500;
                        font-size: 16px;
                        align-items: center;

                        &:first-child {
                            border-top: none;
                        }

                        &:last-child {
                            border-bottom: 0 none;
                        }

                        &::after {
                            transition: transform .3s ease-out;
                            content: "";
                            display: block;
                            width: 14px;
                            height: 14px;
                            background-image: url(../svg/spritesheet1.svg);
                            background-size: 500%;
                            background-position: 100% 0%;
                            background-repeat: no-repeat;
                            transform: rotate(90deg);
                        }

                        &.active {
                            border-bottom: 0 none;

                            &:after {
                                transform: rotate(270deg);
                            }
                        }
                    }
                }

                #faq_search {
                    width: 100% !important;
                    padding: 10px;
                    border: 1px solid #d9d9d9 !important;
                }
            }
        }
    }

    .wrapper_img.right {
        margin-top: 2.5rem;
    }

    form.w-form.w-contact-form.w-login-form {
        margin-top: 1.3rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .w-textarea {
            width: 100%;
        }

        &> :nth-child(6) {
            width: 48.984%;
        }

        &> :nth-child(7) {
            width: 51.010%;

            .w-input-container {
                margin-left: 1.5rem;
            }
        }

        .w-dropdown {
            height: 47.98px;
        }

        .file-input-row {
            margin-top: 5px;
            width: fit-content;
            height: 16px;
            margin-bottom: 12px;

            .ajout_pj_label {
                border: none !important;
                font-family: $secondaryFont;
                font-weight: 500;
                font-size: 11px;
                line-height: 13px;
                text-transform: initial;
                position: relative;

                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $primaryColor;
                    position: absolute;
                    bottom: 20px;
                    left: 0;
                }
            }
        }

        .file-list {
            width: 100%;
            margin-bottom: 10px;
        }

        .uploadedFile {
            margin-top: 5px;
        }

        .closePj {
            display: none !important;
        }

        .uploadedFile {
            .filePJ {
                .remove-file {
                    margin-left: 10px;
                }
            }
        }


        .closePj::before,
        .closePj::after {
                left: 5px;
                top: -1px;
        }

        .optinLightboxContact {
            display: none;
        }

        #recaptcha {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 2rem;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        form.w-form.w-contact-form.w-login-form {
            &> :nth-child(6) {
                width: 48.1% !important;
            }

            &> :nth-child(7) {
                width: 51.9% !important;
            }
        }
    }

    @media screen and (max-width: 1720px) {
        .wrapper_moncompte {
            padding: 0 2rem;

            .container_title {
                margin-right: 5vw;
            }
        }

        .titre_contact {
            padding: 0 2rem;
        }
    }
}