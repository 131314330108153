// Genral wrapper
.customer.body_login {
    .tunnel_track_pusher {
        display: none;
    }

    .newlogin_page {
        .intro {
            font-size: $small_txt;
            text-align: left;
        }

        .login_wrapper .login_form_wrapper form {
            .w-submit-button {
                min-width: 212px;
                padding: 0;
            }
        }
    }
}

body.cart:not(.step_1),
body.checkout {
    .upselling {
        display: none !important;
    }
}

body.cart,
body.checkout {
    #breadcrumbs {
        display: none;
    }
}

body.customer {
    .itm_back_btn {
        display: none;
    }

    .wrapper_panier {
        display: block;

        .edit_adresse {
            display: flex;
        }
    }
}

body.cart,
body.checkout,
body.customer {

    .page_wrapper {
        max-width: $wrapperSmall;
        width: 100%;
        margin: 0 auto;

        @include smallScreen {
            padding: 0 $min_pad;
        }
    }


    // Tunnel Faq
    #title_question_basket,
    #title_other_question {
        font-family: $secondaryFont;
        text-transform: uppercase;
        font-weight: $medium;
    }

    #title_question_basket,
    .bloc_question .faq {
        margin-bottom: 8px;
    }

    .bloc_question {
        font-size: $small_txt;
        line-height: $small_txt_lh;
        margin: 24px auto 0;

        .title_question {
            margin: 24px 0 16px;
        }

        .faq {
            font-family: $primaryFont;

            &:last-child {
                margin-bottom: 0;
            }

            .intitule_question {
                cursor: pointer;
                font-weight: $medium;
            }

            .texte_question {
                font-size: 11px;
                line-height: 14px;
                margin-top: 8px;
            }
        }

        .faq_link,
        .faq_underline {
            text-decoration: underline;
        }

        .faq_theme_wrapper {
            &:last-child {
                .faq {
                    .texte_question {
                        margin-top: 0;
                        line-height: 24px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    // Other questions
    #title_other_question {
        margin: 24px 0 8px;
    }

    #other_question_1 {
        font-size: $small_txt;

        >p {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
}

// Step 2 enter Adresse
body.step_2,
body.step_2_0,
body.customer .wrapper_panier {
    #adresseForm {
        padding-bottom: 0;

        .w-group-label,
        .soloTel {
            display: none;
        }

        .w-form-line.w-submit {
            max-width: unset;
        }

        .w-checkbox-input [name=typeAdresse]+.w-input-label::before {
            width: 26px;
        }

        .w-checkbox-input .w-input-element:checked+.w-input-label::after {
            left: 27px;
        }

        // Remove double * from date de naissance
        .w-date-input .w-input-label {
            span {
                >span {
                    display: none;
                }
            }
        }
    }

    #formPanier {
        display: flex;
        flex-direction: column;


        .checkCgv {
            order: 2;
            margin-top: 40px;

            .w-checkbox-input {
                height: auto;
            }
        }

        >.form_submit {
            order: 1;
        }

        #err_cgv_compulsory {
            order: 3;
            font-size: 14px;
            line-height: 21px;
            margin-top: 8px;
            color: $red;
        }

        .w-checkbox-input .w-input-element+.w-input-label,
        .w-radio-input .w-input-element+.w-input-label {
            align-items: flex-start;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                border: 1px solid $grey;
            }
        }

        .w-checkbox-input .w-input-element:checked+.w-input-label::before,
        .w-radio-input .w-input-element:checked+.w-input-label::before {
            background-color: $primaryColor;
        }

        .w-checkbox-input .w-input-element+.w-input-label span,
        .w-radio-input .w-input-element+.w-input-label span {
            font-family: $primaryFont;
            font-size: $small_txt;
            line-height: $small_txt_lh;
            color: $primaryColor;
            margin-left: 0;
            padding-left: 23px;
        }
    }
}

body.step_2_0 {
    .wrapper_mes_adresses {
        width: 100%;
    }

    .edit_adresse {
        display: flex;
        width: 100%;
    }

    .itm_back_btn {
        display: none;
    }

    #adresseForm {

        .w-group-label,
        .soloTel {
            display: none;
        }

        .w-checkbox-input [name=typeAdresse]+.w-input-label::before {
            width: 26px;
        }

        .w-checkbox-input .w-input-element:checked+.w-input-label::after {
            left: 27px;
        }
    }
}

// Step 2 select delivery
body.step_2 {
    .elem_tunnel .bill_line.total_produit {
        margin-bottom: 3px;
    }
}

// TUNNEL NAV **********************************************************/
.tunnel_track_pusher,
.wrapper_panier .left_side,
.wrapper_panier .bank_left_side {
    max-width: 750px;
    width: 58.6%;
}

.tunnel_track_pusher {
    padding-top: 80px;
    margin-bottom: 80px;

    @include tabletScreen {
        max-width: unset;
        width: 75%;
    }
}

.tunnel_track {
    display: flex;
}

.tunnel_step {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 25%;
    font-size: $small_txt;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 16px;
    border-bottom: 1px solid $grey;


    &.on {
        border-bottom: 1px solid $primaryColor;
        font-family: $secondaryFont;
    }

    // Add 1. || 2. || 3. || 4. for each step dynamically
    &#step-05 {
        &::before {
            content: "4";
            display: block;
        }
    }

    &::before {
        content: attr(data-step);
        display: block;
        line-height: 16px;
    }

    span {
        display: flex;
        line-height: $small_txt_lh;

        &::before {
            content: ".";
            display: block;
            margin-right: 5px;
        }
    }
}

// TUNNEL **********************************************************/
.wrapper_panier {
    display: flex;
}

.elem_tunnel {
    &.left_side {

        .cart_main_title:not(.wishlist),
        .dropdown_cart {
            display: none;
        }

        .product_no_sfs_warning {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 40px;
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 25px;
        }
    }

    .cart_main_title {
        &.wishlist {
            font-family: $secondaryFont;
            text-transform: uppercase;
            font-size: $small_txt;
            line-height: $small_txt_lh;
            margin-bottom: 18px;
        }

        .nb_products {
            display: none;
        }
    }

    &.right_side {
        max-width: 530px;
        width: 41.41%;
        padding-left: 120px;

        @include smallScreen {
            padding-left: pxToVw(120, 1280);
        }

        .cart_product_line {
            margin-bottom: 24px;
            padding-top: 0;

            .cart_product_modify>.product_mod {
                display: none;
            }

            .product_del {
                &::after {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        .product_quantity_change {
            display: none;
        }

        .share_cart {
            display: none;
        }
    }

    .tunnel_sticky {
        position: sticky;
        top: calc(#{$headerHeightSmall} + 40px);
    }

    // Cart recap
    #cart_total {
        border: 1px solid $primaryColor;
        padding: 40px 64px;

        @include smallScreen {
            padding: pxToVw(40, 1920) pxToVw(64, 1920);
        }
    }

    .recap_cart_title {
        font-family: $secondaryFont;
        text-transform: uppercase;
        font-weight: $medium;
        font-size: $small_txt;
        line-height: $small_txt_lh;
        margin-bottom: 40px;
    }

    .bill_line {
        font-size: $small_txt;
        line-height: $small_txt_lh;

        &.total {
            text-transform: uppercase;
        }

        &.used_cp {
            margin-bottom: 24px;
        }

        &.promo,
        &.total {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
        }

        &.total_produit {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;

            &.open {
                label {
                    &::after {
                        content: "+";
                    }
                }
            }

            .nb_products {
                display: none;
            }

            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-family: $secondaryFont;
                font-size: $small_txt;
                line-height: $small_txt_lh;
                padding-bottom: 4px;
                border-bottom: 1px solid $primaryColor;

                &::after {
                    content: "-";
                    display: inline-block;
                    margin-left: 4px;
                }
            }
        }

        &.discount {
            display: flex;
            justify-content: space-between;

            #fdp_txt {
                text-transform: uppercase;
            }
        }

        &.total_produit_dropdown {
            // On maquette : 24px but total_produit has 3px margin-bottom
            margin-top: 21px;
        }
    }

    #keep_alive_coupon {
        margin-bottom: 24px;
    }
}

body.step_2_0,
body.step_2,
body.checkout {
    .elem_tunnel .bill_line {
        &.total {
            margin-top: 24px;
            padding-top: 8px;
            border-top: 1px solid $primaryColor;
        }

        &.promo {
            margin-bottom: 16px;
        }
    }
}

body.step_1 {

    // Remove useless lines in cart recap on step 1
    .pushBasketWrapper,
    .bill_line.total_produit {
        display: none;
    }

    .hide_shippingfees {
        display: none !important;
    }

    #cart_total {
        display: flex;
        flex-direction: column;

        .recap_cart_title {
            order: 1;
        }

        .bill_line.used_cp {
            order: 2;
        }

        .total_produit {
            order: 3;
        }

        .bill_line.discount {
            order: 4;
        }

        .bill_line.promo {
            order: 6;
        }

        .bill_line.total {
            order: 5;
        }

        #keep_alive_coupon {
            order: 6;
        }

        >.upselling {
            order: 7;
            margin-top: 40px;

            progress {
                margin-top: 8px;
            }
        }

        .formPanier_container {
            order: 6;
        }
    }
}

.upselling.price {
    font-size: $small_txt;
    text-transform: uppercase;
    line-height: 18px;

    progress {
        width: 100%;
        appearance: none;
        height: 3px;
        border-radius: 5px;
        vertical-align: top;
        border: 0;
    }

    // Chrome + Safari
    progress::-webkit-progress-bar {
        /* Bar rules */
        background-color: $grey;
        border-radius: 5px;
    }

    progress::-webkit-progress-value {
        /* Value rules */
        background-color: $primaryColor;
        border-radius: 5px;
    }

    // Firefox
    progress::-moz-progress-bar {
        /* style rules */
        background-color: $primaryColor;
    }
}

.pushBasketWrapper {
    margin: 30px 0 pxToVw(40, 1920);

    >a {
        display: block;
    }

    img {
        vertical-align: top;
    }
}

.pushBasketWrapperText {
    display: none;
    width: 100%;
    font-size: $small_txt;
    line-height: $small_txt_lh;
    padding: 16px;
    margin-bottom: 40px;
    border: 1px solid $primaryColor;

    @include smallScreen {
        padding: pxToVw(16, 1920);
    }
}

// Cart / Wishlist tunnel product
.cart_main_table.dropper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .empty_cart {
        width: 100%;
        font-size: $small_txt;
        line-height: $small_txt_lh;
        margin-bottom: 24px;
        text-align: center;
    }

    .cta-holder {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 438px;
        margin: auto;

        .button-cta {
            flex: 1 0 45%;
            max-width: 208px;
            margin-bottom: 20px;

            &.cta-woman,
            &.cta-child {
                margin-right: 20px;
            }
        }
    }

}

.cart_product_line {
    position: relative;
    display: flex;
    max-width: 315px;
    width: 100%;
    font-size: $small_txt;
    padding-top: 32px;
    margin-bottom: pxToVw(45, 1920);

    .dragHandle,
    .dragOverlay,
    .eclat_rect,
    .eclat_rond,
    .eclat_privilege,
    .cart_product_subtitle,
    .cart_product_brand {
        display: none;
    }

    // Eclat basket
    .eclat_rect,
    .eclat_rond,
    .eclat_privilege {
        position: absolute;
        top: 32px; // Padding top of cart product bloc
        height: 20px;

        img {
            height: 100%;
            width: auto;
        }
    }

    .eclat_rond,
    .eclat_privilege {
        left: 0;
    }

    .eclat_rect {
        left: 0;

        &.not_alone {
            left: 13%;
        }
    }

    >.cart_product_desc {
        display: flex;
        align-items: center;
    }

    .cart_product_modify {
        font-size: 11px;

        .product_mod,
        .product_towish,
        .product_del {
            position: absolute;
            top: 0;
        }

        .product_mod {
            left: 0;
        }

        .product_towish {
            left: 82px;
        }

        .product_del {
            right: 0;
        }
    }

    .cart_product_modify>.product_mod,
    .product_towish,
    .wishToBasket span {
        font-family: $secondaryFont;
        font-size: 11px;
        line-height: 14px;
        padding-bottom: 2px;
        border-bottom: 1px solid $primaryColor;
    }

    .product_del,
    .remove_wish {
        font-size: 0;
        height: auto;
        line-height: 1;

        &::after {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            @include bgIconPath($spritesheet);
            @include bgIconCoord(2, $col, $row);
        }
    }

    .cart_product_pic {
        padding-right: 25px;
    }

    .cart_product_img {
        max-width: 120px;
        width: 100%;
    }

    .cart_product_title {
        font-family: $secondaryFont;
        line-height: $small_txt_lh;
        text-transform: uppercase;
    }

    .cart_product_title,
    .wrapper_cart_product_price,
    .cart_product_sizecol:not(.quantity) {
        margin-bottom: 8px;
    }

    .cart_product_nostock {
        .product_nostock_label {
            span {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                font-size: 100%;

                &::before {
                    content: '';
                    display: block;
                    width: .6rem;
                    height: .6rem;
                    border-radius: 50%;
                    background-color: #b72c00;
                    margin-right: .5em;
                }
            }
        }
    }

    .wrapper_cart_product_price {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        line-height: $small_txt_lh;

        .pricetag {
            &.was {
                @extend .crossed;
                margin-right: 8px;

                &::after {
                    bottom: 6px;
                }
            }
        }
    }

    .product_quantity_change {
        .load_qte {
            position: absolute;
            bottom: 9px;
            left: 230px;
        }

        .qteWrapper {
            position: absolute;
            bottom: 7px;
            left: 210px;
            display: flex;
            align-items: center;
            box-sizing: border-box;

            button {
                width: auto;
                height: auto;
                background-color: transparent;
                border: 0;
                color: $primaryColor;
                font-size: .8rem;
                font-weight: 400;
                outline: none;
            }

            input[type="text"] {
                border: 0;
                text-align: center;
                width: 30px;
                padding: 0;
            }
        }

        .selected_qte {
            font-family: $primaryFont;
        }

        .change_qte {
            cursor: pointer;
        }
    }

    &:has(.cart_product_nostock) {

        .cart_product_pic,
        .wrapper_cart_product_desc {
            opacity: 0.5;
        }
    }
}

.cart_main_table.wishlist {
    .cart_product_line {
        .cart_product_modify {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .remove_wish {
            position: absolute;
            top: 0;
            right: 0;
        }

        .tunnel_wish_nostock {
            position: absolute;
            top: calc(32px + 120px);
            transform: translateY(-100%);
            padding: 0.5rem;
            text-align: center;
            display: block;
            width: 120px;
            background: rgba(255, 255, 255, .5);
        }

        .wrapper_cart_product_price {
            flex-direction: row;
            justify-content: flex-start;
        }

        .wishToBasket {
            height: auto;
            text-transform: initial;
            border: 0;

            >span {
                text-transform: initial;
            }
        }

        .wishToBasket {
            width: auto;
        }
    }
}


// LIVRAISON
.bloc_livraison {
    .content_form>.w-input-container {
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 11px;
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            @include maskIconPath($spritesheet);
            @include maskIconCoord(5, $col, $row);
            background-color: $primaryColor;
            transform: translateY(-50%);
        }
    }
}

.bloc_livraison,
body.checkout .wrapper_bloc_banque {
    position: relative;
    display: block;
    border: 1px solid $grey;
    margin-bottom: 50px;
    padding: 51px 25px 32px;


    // Checkbox / radios ***************
    input[name='choix_livraison'] {
        display: none;
    }

    .checkbox,
    .radio {
        cursor: pointer;
        position: absolute;
        z-index: 1;
    }

    .checkbox,
    .radio {
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        background: $white;
        border: 1px solid $grey;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
    }

    &:hover,
    &.active,
    &.actif {
        border-color: $primaryColor;

        .checkbox,
        .radio {
            border-color: $primaryColor;
            background: $primaryColor;
            box-shadow: inset 0 0 0 2px $white;
        }
    }

    // *************************

    .title_type_livraison,
    .transporteur_info,
    .bank_title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        font-size: $small_txt;
        font-weight: $medium;
        line-height: $small_txt_lh;
        padding: 0 24px;
    }

    .title_type_livraison,
    .bank_title {
        font-family: $secondaryFont;
        background-color: $secondaryColor;
        padding: 0 24px;
    }

    .transporteur_info {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(50% + 4px));
    }


    // Delivery infos
    .choix_delivery {
        position: relative;
    }

    .left_delivery,
    .mid_delivery,
    .right_delivery,
    .adresse_change,
    .wrapper_locate_relais {
        font-size: $small_txt;
        line-height: $small_txt_lh;
    }

    .left_delivery,
    .mid_delivery {
        .shop_name {
            order: 1;
            font-family: $secondaryFont;
            margin-bottom: 4px;

            i {
                display: none;
            }
        }
    }

    .right_delivery {
        position: absolute;
        bottom: 0;
        right: 0;
        font-family: $secondaryFont;
        display: flex;
        justify-content: space-between;
    }

    .civilite {
        font-family: $secondaryFont;
        margin-bottom: 4px;
    }

    .show_modif_info {
        display: inline-block;
        width: auto;
        height: auto;
        font-family: $secondaryFont;
        line-height: $small_txt_lh;
        font-weight: $medium;
        background-color: transparent;
        margin: 8px 0;
        padding-bottom: 2px;
        border: 0;
        border-bottom: 1px solid $primaryColor;

        &:hover {
            font-family: $secondaryFont !important;
            background-color: transparent !important;
            color: $primaryColor !important;

            span {
                font-family: $secondaryFont !important;
                background-color: transparent !important;
                color: $primaryColor !important;
            }
        }

        span {
            display: inline-block;
            width: auto;
            height: auto;
            font-size: 11px;
            line-height: 14px;
            text-transform: initial;
        }
    }

    .delivery_form_title {
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        margin: 8px 0 13px;
    }

    .adresse_change {
        .w-dropdown {
            height: 3.42857em;
        }

        select.w-input-element {
            font-size: 14px;
        }
    }

    // Delivery pickup
    .store_no_result {
        margin-top: 8px;
    }

    .displayed_result {
        display: flex;
        max-height: 300px;
        margin-top: 13px;

        .sidebar-right {
            opacity: 1 !important;
            padding-left: 16px;
            width: 34%;
            height: 300px;
        }

        @include smallScreen {
            max-height: pxToVw(300, 1920);

            .sidebar-right {
                padding-left: pxToVw(16, 1920);
                height: pxToVw(300, 1920);
            }
        }

    }

    .details {
        .kp {
            max-width: 90%;
            font-family: $primaryFont;
            font-size: $small_txt;
            line-height: $small_txt_lh;
            padding-bottom: 12px;
            margin-bottom: 8px;
            border-bottom: 1px solid $primaryColor;


            .btn_container {
                .relayLink {
                    display: inline-block;
                    font-family: $secondaryFont;
                    font-size: 11px;
                    line-height: 13px;
                    padding-bottom: 3px;
                    border-bottom: 1px solid $primaryColor;
                }
            }
        }

        .distance {
            display: none;
        }

        .kpname {
            display: inline-block;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .address {
            margin-bottom: 8px;
            text-transform: lowercase;
        }
    }

    #map_canvas {
        max-width: 495px;
        width: 66%;
        height: 300px;

        @include smallScreen {
            max-width: pxToVw(495, 1920);
            height: pxToVw(300, 1920);
        }

        .horaire_title {
            display: none;
        }

        .button.relayLink {
            display: inline-block;
            color: $primaryColor;
            font-size: 11px;
            font-family: $secondaryFont;
            text-transform: lowercase;
            width: auto;
            height: auto;
            border: none;
            padding-bottom: 3px;
            border-bottom: 1px solid $primaryColor;

            &::first-letter {
                text-transform: uppercase;
            }

            &:hover {
                background-color: unset !important;
                color: $primaryColor;
            }
        }
    }
}

// Special position due to different HTML on mondial RELAY BLOC
.transporteur_Mondial {

    .title_type_livraison,
    .transporteur_info>span {
        display: block !important;
    }

    .transporteur_info {
        img {
            display: none;
        }
    }

    .choix_delivery {
        position: initial;
    }

    .mid_delivery {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mid_delivery>.adresse {
        order: 2;
    }

    #btn_another_relaymondialrelay_relais {
        order: 3;
    }

    .transporteur_delay {
        order: 4;

        strong {
            font-weight: $medium;
        }
    }

    .right_delivery {
        bottom: unset;
        top: 120px;
        right: 25px;
    }
}

// CHECKOUT ************************************************************/
body.checkout {
    .wrapper_bloc_banque {
        &.flexco {
            .cards-wrapper {
                margin: 1rem 0;

                img {
                    object-fit: contain;
                    height: 2.5rem;
                }

                #inputVISA_big,
                #inputCB_big {
                    width: 2.5rem;
                }

                #inputMASTERCARD_big {
                    width: 5.7rem;
                }

                #inputAMEX_big {
                    width: 4rem;
                }

                #inputMAESTRO_big {
                    width: 3rem;
                }
            }

            .bank_subtitle,
            .total_transaction {
                font-size: $small_txt;
                line-height: $small_txt_lh;
            }

            .bank_subtitle {
                strong {
                    font-weight: $medium;
                }

                >span:last-of-type {
                    display: none;
                }
            }

            .total_transaction {
                .pricetag {
                    font-family: $secondaryFont;
                }
            }
        }

        &.paypal {

            .bank_title span {
                position: relative;
                display: flex;
                align-items: center;
                line-height: 1;

                &::after {
                    content: "";
                    display: inline-block;
                    background-image: url(../banque/logo/paypal_logo_txt.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-color: #FFF;
                    width: 66px;
                    height: 16px;
                    margin-left: .5rem;
                    vertical-align: middle;
                }
            }

            .bloc_bank_contents {
                display: block;
                text-align: center;

                .bank_subtitle {
                    color: $black;
                }
            }

            .bank_subtitle {
                display: inline-block;
                font-size: 11px;
                line-height: 14px;
                color: $primaryColor;
                margin: 1rem auto 1rem;
                padding-bottom: 3px;
                border-bottom: 1px solid $primaryColor;
            }

            #inputPAYPAL {
                opacity: 0;
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &#hipay_applepay_bloc {
            .total_transaction {
                display: none;
            }

            #hipay-apple-pay-button {
                position: relative;
                height: 2.1rem;
                padding: 0;
                overflow: hidden;
                max-width: 19rem;
                margin: 1rem auto;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 10px;
                    width: 5.2rem;
                    height: 6.95rem;
                    top: 0;
                    z-index: 2;
                    background-color: $white;
                }

                &:after {
                    left: inherit;
                    width: 5.3rem;
                    right: 0;
                }

                iframe {
                    position: absolute;
                    top: -5px;
                    bottom: 0;
                    left: 28.5%;
                    right: 0;
                    margin: auto -0.2rem;
                    width: 42%;
                    height: 2rem;
                }

                @media only screen and (max-width: 629px) {
                    &:before {
                        width: 4.1rem;
                    }

                    &:after {
                        width: 3.3rem;
                    }

                    iframe {
                        width: 48%;
                    }
                }

                @media only screen and (max-width: 657px) {
                    &:before {
                        width: 4.4rem;
                    }

                    iframe {
                        left: 27%;
                        width: 45%;
                    }
                }

                @media only screen and (max-width: 592px) {
                    &:before {
                        width: 3.5rem;
                    }

                    iframe {
                        left: 25%;
                        width: 51%;
                    }
                }

                @media only screen and (max-width: 566px) {
                    &:before {
                        width: 2.9rem;
                    }

                    iframe {
                        left: 23%;
                        width: 55%;
                    }
                }

                @media only screen and (max-width: 534px) {
                    &:before {
                        width: 2.3rem;
                    }

                    &:after {
                        width: 3rem;
                    }

                    iframe {
                        left: 21%;
                        width: 60%;
                    }
                }

                @media only screen and (max-width: 510px) {
                    &:before {
                        width: 1.9rem;
                    }

                    &:after {
                        width: 1rem;
                    }

                    iframe {
                        left: 19%;
                        width: 63%;
                    }
                }

                @media only screen and (max-width: 490px) {
                    &:before {
                        width: 1.4rem;
                    }

                    iframe {
                        left: 17%;
                        width: 67%;
                    }
                }

                @media only screen and (max-width: 465px) {
                    &:before {
                        width: 1.1rem;
                    }

                    iframe {
                        left: 15%;
                        width: 72%;
                    }
                }
            }
        }
    }

    #cart_total {
        display: flex;
        flex-direction: column;
        padding: 0;
        border: 0;

        .wrapper_payment {
            order: 1;
        }

        .recap_cart_title {
            order: 2;
        }
    }

    .elem_tunnel .bill_line.promo {
        // Override margin on promo line
        margin-bottom: 0;
    }

    .bill_line {
        padding: 0 64px;
        border-left: 1px solid $primaryColor;
        border-right: 1px solid $primaryColor;

        &.used_cp {
            display: none;
        }

        &.promo {
            order: 5;
            padding-bottom: 16px;
        }

        &.total {
            position: relative;
            order: 7;
            padding: 0 64px 40px;
            margin: 0 !important;
            border-top: none !important;
            border-bottom: 1px solid $primaryColor;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 69%;
                height: 1px;
                background-color: $primaryColor;
            }
        }

        &.total_produit {
            order: 3;
            margin: 0;
            padding-bottom: 8px;
            cursor: initial;

            >label {
                font-family: $primaryFont;
                text-transform: initial;
                text-decoration: none;
                padding: 0;
                border: 0;

                &::after {
                    content: none;
                    display: none;
                }
            }
        }

        &.total_produit_dropdown {
            order: 4;
            display: none;
            margin: 0;
        }

        &.discount {
            order: 6;
            padding-bottom: 24px;
        }
    }

    #wrapper_payement_secur {
        .recap_cart_title {
            margin-top: 24px;
            margin-bottom: 8px;
        }

        .tunel_paiement_right_description {
            font-size: $small_txt;
            line-height: $small_txt_lh;
        }
    }

    .bloc_question {
        display: none;
    }

    .payment_addr_wrapper {
        position: relative;
        margin-bottom: 26px;

        &:last-child {
            margin-bottom: 24px;
        }

        .title,
        .address>p {
            font-size: $small_txt;
            line-height: $small_txt_lh;
        }

        .title {
            font-family: $secondaryFont;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .address_mod {
            position: absolute;
            top: 0;
            right: 0;
            font-family: $secondaryFont;
            font-size: 11px;
            line-height: 13px;
            padding-bottom: 3px;
            border-bottom: 1px solid $primaryColor;
        }
    }

    .main_table .recap_cart_title {
        margin: 0;
        padding: 40px 64px 40px;
        border-top: 1px solid $primaryColor;
        border-left: 1px solid $primaryColor;
        border-right: 1px solid $primaryColor;
    }


    // Bank Thanks ****************
    .thanksWrapper {
        #around_me_map {
            display: none;
        }
    }

    .bankThanksTop {
        display: flex;

        >h1 {
            display: flex;
            flex-direction: column;
            justify-content: unset;
            align-items: unset;
            font-family: $secondaryFont;
            font-size: $small_txt;
            line-height: 18px;
            text-transform: uppercase;
            letter-spacing: 0;

            &::before {
                content: none;
                display: none;
            }


            >span {
                display: inline-block;
                font-family: $primaryFont;
                margin-top: 16px;
            }
        }
    }

    .seeOrderEvo {
        font-size: $small_txt;
        line-height: 18px;
        text-transform: uppercase;
    }

    .seeOrderEvo,
    .thanksTrust {
        strong {
            font-weight: $medium;
        }
    }

    .thanksTrust {
        display: none;
        text-transform: uppercase;
        font-size: 13px;
        line-height: $small_txt_lh;
    }

    .bankThanksBtn {
        max-width: 350px;
    }

    .thanksRecap {

        #clientForm {
            max-width: 350px;
        }

        .thanksLeft {
            max-width: 750px;
            width: 58.6%;
        }

        .thanksRight {
            width: 33%;
            text-align: unset;

            @include smallScreen {
                width: auto;
            }
        }

        @media screen and (max-width: 1150px) {
            flex-direction: column;
            align-items: unset;
            justify-content: unset;

            .thanksLeft {
                max-width: 100%;
                width: auto;
            }

            .thanksRight {
                margin-top: 24px;
                max-width: 425px;
                width: 100%;
            }
        }
    }

    .createPwdTxt {
        font-size: $small_txt;
        line-height: $small_txt_lh;
        text-transform: uppercase;
        margin-bottom: 43px;
    }

    .bankThanksBtn {
        margin-top: 40px;
    }

    .thanksRight {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 40px 40px;
        margin-top: -68px;
        border: 1px solid $primaryColor;

        .thanksRightWrapper {
            width: 100%;
        }

        p {
            display: flex;
            font-size: $small_txt;
            line-height: $small_txt_lh;

            &:not(.txt_thanks_transaction) {
                margin: 0 !important;
            }

            label {
                white-space: nowrap;

                &:after {
                    content: " :";
                    margin-right: 5px;
                }
            }

            span {
                white-space: nowrap;
                text-align: left;
            }

            &.txt_thanks_transaction {
                text-align: unset;
                text-transform: initial;
                margin: 24px 0 0;
            }
        }
    }

    .bank_left_side .txt_recommencer_paiement {
        margin-bottom: 15px;
    }
}

form#hipay-3-form {
    padding: 0;

    .form_line.saveAlias {
        display: flex;
        column-gap: 1rem;
    }
}

#outsite_ue_notice {
    margin-top: -45px;
    margin-bottom: 45px;
    font-size: $small_txt;
    line-height: $small_txt_lh;
    max-width: 750px;
}

.step_2.in_tunnel #top_wrapper,
.checkout.in_tunnel #top_wrapper,
.step_2_0.in_tunnel #top_wrapper {

    .header_main_wrapper,
    .header_rightSide {
        display: none;
    }

    .header {
        display: flex;
        justify-content: center;

        .header_leftSide.header_logo .header_title {
            margin: 30px 0 30px 0;
        }
    }
}

html[lang="en"] {
    body {
        &.checkout {
            .wrapper_bloc_banque#hipay_applepay_bloc {
                #hipay-apple-pay-button {
                    max-width: 19.7rem;

                    iframe {
                        height: 1.9rem;
                        top: -17px;
                    }

                    &:before {
                        width: 6.0rem;
                    }

                    &:after {
                        width: 6.8rem;
                    }
                }
            }
        }
    }
}