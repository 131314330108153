/***** Page compte *****/

//form information and form adresses
.wrapper_moncompte {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 80px auto 0 auto;

    .container_title {
        font-family: $primaryColor;
        font-style: normal;
        font-weight: $medium;
        font-size: 13px;
        line-height: $small_txt_lh;
        text-transform: uppercase;
        .title_infos {
            font-family: $secondaryFont;
            margin-bottom: 40px;
            font-weight: $medium;
        }
        .item {
            margin-bottom: 16px;
            &.actif {
                font-family: $secondaryFont;
            }
        }
    }
    .w-submit {
        max-width: max-content;
        .w-submit-button {
            @extend .primary;
        }
    }
    .wrapper_content_password .w-submit-button{
        padding: 0 40px;
    }

    .wrapper_moncompte_main {
        max-width: 1054px;
        width: 100%;

        .wrapper_new_message {
            .new-message {
                width: fit-content;
                display: block;
                margin-bottom: 3em;
            }

            .intro {
                font-family: $primaryFont;
                font-size: .875rem;
                line-height: 1.5;
                margin-bottom: 10px;
                max-width: 65%;
            }
        }

        .wrapper_message {
            margin-right: 7.5%;
            .details_msg_content {
                padding-bottom: 12px;
                border-bottom: #2b2b2b 1px dotted;
                display: flex;
                flex-direction: column;
                gap: 1.2em;

                .infos_message > p {
                    padding-bottom: 2px;
                    label {
                        font-weight: 600;
                        opacity: 0.8;
                    }
                }

                .detail_msg_label {
                    padding-bottom: 2px;

                    label {
                        font-weight: 600;
                        opacity: 0.8;
                    }
                }

                .message_pj_container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0 10px;
                }

                .message_detail_pj {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    margin-top: 10px;
                    border: 1px solid #AFAFAF;
                    @extend .light;
                    padding: 0;

                    a {
                        font-weight: 500;
                        font-family: $secondaryFont;
                        padding: 0 40px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .table_mes_messages {
                width: 100%;
                .table_header{
                    color: $primaryColor;
                    text-transform: uppercase;
                    .header_item{
                        font-size: 0.875rem;
                        font-weight: 700;
                        padding: 10px 0;

                        &:first-of-type{
                            padding-left: 1.5rem;
                        }

                        &:nth-of-type(2){
                            @media screen and (max-width: 1360px) {
                                width: 25%;
                            }
                        }
                    }
                }

                .content{
                    margin-top: 1.2rem;
                }

                .file-input-row {
                    padding-bottom: 2em;
                    display: none;

                    .ajout_pj_label {
                        padding-bottom: 6px;
                    }
                }

                .file-list {
                    margin-top: 0.5rem;

                    .rowList {
                        display: flex;
                        align-items: center;

                        button {
                            &.closePj {
                                position: relative;
                                width: 1rem;
                                height: 1rem;
                                margin-left: 1rem;
                                opacity: 0.6;
                                cursor: pointer;

                                &:hover {
                                    opacity: 1;
                                }

                                &:before,
                                &:after {
                                    content: "";
                                    position: absolute;
                                    width: 2px;
                                    height: 0.7rem;
                                    left: 5px;
                                    top: 0;
                                    background-color: black;
                                }

                                &:before {
                                    transform: rotate(45deg);
                                }

                                &:after {
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }

            .unread{
                font-weight: 700;
            }

            tr:not(:first-child){
                border-bottom: 1px solid $primaryColor;
            }

            .read, .unread {
                .enveloppe{
                    padding: 1.25rem 1.5rem;
                }

                .btn_ouvrir_message{
                    padding-right: 1.5rem;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    cursor: pointer;
                }
            }

            .w-form.w-reply-form {
                .w-form-line {
                    padding-top: 4px;

                    textarea,
                    .w-input-label {
                        font-family: $primaryFont;
                    }
                }
            }
        }

        .menu_wrapper {
            width: 226px;
            .actif {
                font-weight: 600;
            }
        }
        .holder_content {
            width: 100%;
            .primary-title {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: $medium;
                font-size: 13px;
                line-height: $small_txt_lh;
                text-transform: uppercase;
                margin-bottom: 40px;
            }
        }
        .w-radio-input {
            span {
                color: $primaryColor;
            }
        }
    }
    .form_submit.w-submit {
        margin: 80px 0 100px 0;

        .w-submit-button{
            width: max-content;
            display: block;
            span {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: $small_txt_lh;
            }
        }
    }

    .w-date-input .w-input-label > span > span {
        display: none;
    }
}

.customer,
.newsletter,
.order {
    .button,
    .w-submit-button,
    button.w-submit-button {
        padding: 0 40px;
    }
}

#adresseForm {
    padding-bottom: 100px;
    .w-checkbox-input [name="typeAdresse"] + .w-input-label {
        &::before {
            border-color: #b1b1b1;
            width: 15px;
            height: 15px;
        }
        span {
            color: $primaryColor;
        }
    }
    .choix_type_adresse {
        display: flex;

        .type_adresse {
            position: relative;
            margin: 0 40px 30px 0;
            width: max-content;
            padding: 0 3px 3px 3px;
            text-transform: uppercase;

            &.actif::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
                background-color: black;
            }
        }
        .ami {
            display: none;
        }
    }
    .type_adresse {
        cursor: pointer;
        p {
            font-size: 13px;
        }
    }
    .w-input-container {
        .w-dropdown {
            height: 100%;
        }
    }
    .w-input {
        .w-input-label {
            color: #868686;
        }
    }

    // overrides
    .w-form-line.sameBillAddress,
    .w-input-group.accountCreate {
        .w-input-container {
            height: auto;
        }
    }

    .w-radio-group {
        .w-input-label {
            color: $primaryColor;
        }
    }
}

//display all address
.conteneur_adresse {
    padding-top: 2px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 40px;

    .adresse {
        padding: 24px;
        width: 100%;
        border: 1px solid $primaryColor;
        color: $primaryColor;
        font-weight: $medium;

        .ico_adresse {
            display: none;
        }
        div > div:not(:last-child) {
            margin-bottom: 16px;
        }

        .addr_type {
            font-family: $primaryFont;
            color: inherit;
            font-size: 13px;
            text-transform: uppercase;
        }

        .infos .addr_line span{
            font-weight: 500;
            color: inherit;
            font-size: 13px;

            &.country {
                display: none;
            }
        }

        .modlinks_wrapper .modlink {
            display: inline-block;
            height: 19px;
            margin-right: 22px;
            position: relative;
            padding-bottom: 1px;

            span {
                font-family: $secondaryFont;
                font-weight: $medium;
                color: inherit;
                font-size: 11px;
                line-height: 13px;
            }

            &::after {
                position: absolute;
                content: "";
                height: 1px;
                left: 0;
                right: 0;
                bottom: 0px;
                background-color: $primaryColor;
            }
        }
    }
}

.content_newsletter,
.wrapper_form {
    display: flex;
    flex-direction: column;

    .update_success {
        margin-bottom: 20px;
        font-size: 13px;
    }
}

form#subscriptionForm {
    .w-input.w-checkbox-input {
        height: auto;
    }

    .w-form-line {
        margin-bottom: 40px;

        .w-nonempty [name="newsletterMail"] + .w-input-label span {
            font-size: 12px;
        }

        &.optin-select-line {
            margin-bottom: 24px;
        }
    }

    .w-input input[type="checkbox"] {
        & + .w-input-label {
            display: block;
            position: relative;
            padding-left: 50px;
            font-size: 13px;
            font-weight: 400;
            font-family: $primaryFont;
            color: $primaryColor;

            strong {
                display: block;
                font-family: $secondaryFont;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
                margin-bottom: 7px;
                color: $primaryColor;
            }
        }

        & + .w-input-label::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 26px;
            height: 15px;
            border-radius: 3rem;
            border: 1px solid #e5e5e5;
            background: #e5e5e5;
            box-shadow: inset 0 0 0 0 #e5e5e5;
        }

        & + .w-input-label::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border: 2px solid #e5e5e5;
            background-color: $secondaryColor;
        }

        &:checked + .w-input-label::before {
            background: #707070;
            border-color: #707070;
        }

        &:checked + .w-input-label::after {
            left: 12px;
            border-color: #707070;
        }
    }

    .w-submit {
        margin: 40px auto 120px 0;
    }

    .multiline > span{
        font-weight: 500;
    }
}

.wrapper_mes_commandes {
    .intro {
        font-family: $primaryFont;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 48px;
    }

    .flexAccount {
        margin-top: 48px;
    }

    .date_select {
        height: 48px;
        width: 250px;
        position: relative;

        #year {
            border: 1px solid #868686;
            width: 250px;
            padding: 0 16px;
            border-radius: 0;
            -moz-appearance:none;
            -webkit-appearance:none;
            appearance:none;
            background-color: transparent;
            outline: none;
            cursor: pointer;
        }

        .arrow {
            right: 0;
            bottom: 0;
            position: absolute;
            transform: translate(-17px, -17px);
            z-index: -1;
        }
    }

    .clearfix {
        margin-left: 120px;

        .actif {
            font-weight: 600;
        }

        .onglet {
            display: flex;
            align-items: center;

            > a, > span {
                display: block;
                text-transform: uppercase;
                font-size: 13px;
            }
        }
    }

    @media (max-width: 1024px) {

        .clearfix {
            margin-left: 1rem;
        }
    }
    .wrapper_tableau {
        margin-bottom: 100px;

        .wrapper_menu_tab {
            display: flex;
            margin-bottom: 24px;

            option {
                background-color: $secondaryColor;
            }
        }

        .table-header {
            border: 1px solid $primaryColor;
            height: 48px;
            font-size: 13px;
            text-transform: capitalize;

            .label-bloc {
                display: flex;
            }
        }

        .date_select {
            width: 250;
            height: 48px;

            cursor: pointer;
            select {
                width: 100%;
                height: 100%;
            }
        }

        .clearfix {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        th {
            padding-top: 16px;
            padding-bottom: 16px;
            font-family: $secondaryFont;
            font-weight: 500;

            &:last-child {
                padding-right: 16px;
            }

            &:first-child {
                padding-left: 16px;
            }
        }

        .rollover {
            border-bottom: 1px solid $primaryColor;

            td {
                padding-top: 24px;
                padding-bottom: 24px;
                font-size: 13px;
                font-weight: 500;

                .facture-bloc:not(:last-child) .f-item {
                    padding-bottom: 10px;
                }

                .refund {
                    font-size: 10px;
                }

                &.montant {
                    font-family: $secondaryFont;
                }

                &:last-child,
                .facture-bloc .f-item {
                    padding-right: 16px;

                    a {
                        font-size: 11px;
                        font-family: $secondaryFont;
                        position: relative;

                        &::after {
                            content: "";
                            width: 100%;
                            height: 1px;
                            background-color: $primaryColor;
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                        }
                    }
                }

                &:first-child {
                    padding-left: 16px;
                }
            }
        }

        .nothing {
            padding-top: 8px;
        }
    }
}

@media screen and (max-width: 1720px){
    .wrapper_moncompte {
        padding: 0 2rem;

        .container_title{
            margin-right: 5vw;
        }
    }
}

.content_password {
    flex-direction: column;
    margin-top: 0;
    .wrapper_form {
        width: 100%;
    }
    .w-submit{
        margin: 40px 0 120px 0;
    }
}

.popup_numero_retour {
    display: none;
    margin: auto;
    padding: 96px 40px 40px 64px;
    width: 720px;
    min-height: 317px;
    max-height: 610px;
    height: max-content;
    &.actif {
        display: block;
        position: absolute;
        z-index: 11;
    }
    .close_pop {
        position: absolute;
        span::after {
            content: "";
            background-image: url(../img/close-modal.png);
            background-position: center;
            background-repeat: no-repeat;
            height: 16px;
            width: 14px;
            display: block;
        }
    }
}

.popup_numero_retour,
.wrapper_content_cmd_detail {
    .texte_num_retour {
        font-family: $secondaryFont;
        text-transform: uppercase;
        margin-bottom: 24px;
        width: 100px;
    }
    .holder_return_product.none, .warning_return_not_available.none {
        opacity: 0.5;
        pointer-events: none;
        font-size: 13px;
    }

    .holder_return_product {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        &:not(:last-child){
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: #868686 1px solid;
        }
    }
    .recommandation p {
        font-size: 13px;
        width: 430px;
    }
    .product-link {
        height: fit-content;
        display: flex;
        align-items: center;
        .cart_thumb_holder,
        .imgWrapper {
            margin-right: 32px;
            max-height: 115px;
        }
        .sub_title {
            font-family: $secondaryFont;
        }
        .price_tag,
        .pricetag,
        .holder_subs,
        .sub_title {
            font-weight: 500;
            font-size: 11px;
            padding-bottom: 8px;
            > div,
            > .sub {
                padding-bottom: 4px;
            }
        }
    }
    .holder_form {
        margin-top: 40px;
    }
    .holder_wrapper {
        max-height: 245px;
        min-height: 130px;
        overflow: auto;
    }

    .date_select {
        height: 48px;
        width: 100%;
        position: relative;
        border: solid 1px #868686;

        &::after {
            position: absolute;
            content: "";
            background-image: url(../img/dropdown_arrow.png);
            background-position: center;
            background-repeat: no-repeat;
            height: 48px;
            width: 12px;
            bottom: -2px;
            right: 16px;
            z-index: -1;
        }
        .return_quantity,
        .return_choice {
            padding: 0 54px 0 16px;
            background-color: transparent;
            height: 100%;
            border: none;
            -moz-appearance:none;
            -webkit-appearance:none;
            appearance:none;
            &:focus-visible {
                outline: none;
            }
        }
        .arrow {
            right: 0;
            bottom: 0;
            position: absolute;
            transform: translate(-17px, -17px);
            z-index: -1;
        }
    }

    .selectWrapper.js-select-motif {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .os-scrollbar-horizontal {
        display: none;
    }

    .qty_dropdown {
        width: min-content;
        margin-bottom: 8px;
    }

    #btnPrintPdf .button {
        margin: 16px 0;
        padding: 0 60px;
        width: fit-content;
    }
    #printPdf {
        margin-bottom: 16px;
    }
    .content-adresse {
        width: 272px;
        line-height: 15px;
        + small {
            display: block;
            width: 200px;
            line-height: 15px;
        }
    }
    .expiredRetractation {
        margin-bottom: 16px;
    }
    .cmd_suivi_wrapper a,
    .content .return-link,
    .link_retour {
        font-size: 11px;
        font-family: $secondaryFont;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 1px;
            bottom: -3px;
            left: 0;
            background-color: black;
        }
    }
    .cmd_suivi_wrapper.cmd_retour_wrapper {
        > div:not(:last-child) {
            margin-bottom: 25px;
        }
    }
    .adresse-question {
        margin-bottom: 20px;
    }
    .divBtnPrintPdf {
        display: flex;
        justify-content: center;
        margin: 40px auto;

        .button {
            width: max-content;
        }
    }
    .go_back {
        margin-bottom: 24px;
        position: relative;

        span {
            font-weight: 500;
            font-size: 13px;
            margin-left: 15px;

            &::before {
                position: absolute;
                content: "";
                background-image: url(../img/arrow-return.png);
                background-position: center;
                background-repeat: no-repeat;
                width: 6px;
                height: 18px;
                left: 0;
            }
        }
    }
    .wrapper_infos_cmd {
        padding: 40px;
        display: flex;
        justify-content: space-between;
        border: 1px solid black;
        margin-bottom: 40px;

        @media (max-width: 1100px) {
            .cmd_info_wrapper {
                width: min-content;
                p {
                    display: inline-block;
                }
            }
        }

        .wrapper_delivery_addr {
            .txt-content:not(:last-child) {
                padding-bottom: 5px;
            }
        }
        > div {
            font-weight: 500;
            font-size: 13px;
            width: fit-content;

            .txt-title {
                font-family: $secondaryFont;
                text-transform: uppercase;
                margin-bottom: 16px;
            }
        }
        .addr_country {
            display: none;
        }
    }
    .commande-container {
        display: flex;
        justify-content: space-between;
    }
    .cmd_items {
        display: grid;
        grid-template-rows: repeat(auto-fill,130px);
        grid-template-columns: repeat(auto-fill,minmax(220px, 1fr));
        flex-basis: 592px;
        row-gap: 20px;
    }
    .wrapper_right {
        font-weight: 500;
        font-size: 13px;
        width: 302px;

        .titre {
            font-family: $secondaryFont;
            margin-bottom: 48px;
            line-height: 15px;
        }
        .clearfix:not(.montant_total_wrap) {
            margin-bottom: 8px;
        }
        .montant_total_wrap {
            margin-top: 24px;
            margin-bottom: 48px;
            padding-top: 6px;
            border-top: solid $primaryColor 1px;
        }
        .clearfix {
            display: flex;
            justify-content: space-between;
        }
        #pdfDIV {
            margin: 16px 0;
            width: fit-content;
        }
    }
}

#sendwishlistbox {
    width: 688px;
    max-height: 90%;
    overflow: auto;

    #sendfriend_alert_mail {
        margin-bottom: 40px;
    }

    #sendfriend_alert_mail ~ .popup_title {
        display: none;
    }

    #sendfriend_alert_mail > div,
    #sendfriend_alert_mail > p {
        width: fit-content;
    }

    #sendfriend_alert_mail > div {
        font-family: $secondaryFont !important;
        font-size: 13px !important;
        font-weight: 500 !important;
    }

    #sendfriend_alert_mail ~ #wrapper_sendwishlist {
        display: none;
    }

    .close_pop {
        top: 40px;
        right: 40px;
        font-weight: 500;
        padding: 0;
        font-size: 20px;
        font-family: $secondaryFont;
        background-color: transparent;
        border: none;
    }

    p:not(.title) {
        font-size: 13px;
        font-family: $primaryFont;
    }

    .title {
        font-family: $secondaryFont;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .subtitle {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 16px;
    }

    .w-form-line.w-submit {
        max-width: 100%;
        display: flex;
        justify-content: center;

        .w-btn-wrapper {
            max-width: max-content;
        }
        .w-submit-button {
            padding: 0 40px;
        }
    }

    .form_title {
        text-transform: uppercase;
        margin-bottom: 8px;
        font-size: 13px;
    }

    #sendfriend_form_to .w-form-line:nth-child(2) {
        margin-bottom: 44px;
    }

    #sendfriend_form_to, #sendfriend_form_froms {
        .w-input-note {
            display: block;
            font-size: 10px;
            margin-bottom: 24px;
        }
    }

    #sendfriend_form_to .w-form-line:nth-child(3) {
        margin-bottom: 27px;
        .w-textarea .w-input-element {
            outline: none;
        }
    }

    .w-checkbox-input .w-input-label {
        color: $primaryColor;
        &::before {
            border-color: #b1b1b1;
        }
    }
    .w-input-error + .w-input-note {
        transform: translateY(14px);
    }
    #recaptcha {
        scale: 0.79;
        > div {
            display: flex;
            justify-content: center;
            width: auto !important;
            height: auto !important;
        }
    }
    #sendfriend_form_froms {
        margin-top: 20px;

        .w-form-line:nth-child(4) {
            margin-bottom: 23px;
        }
    }
    #sendfriend_form_buttons {
        max-width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .w-btn-wrapper {
            width: max-content;
        }
        .w-submit-button {
            padding: 0 40px;
        }
    }
}
.wrapper_content_wishlist {
    .wishlist_container{
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        .wrapper_totaux {
            max-width: 250px;
            width: 100%;
            margin-left: 20px;
        }
        .w-left-bloc {
            max-width: 726px;
            width: 100%;
        }
        .bloc_price {
            font-size: 16px;
            display: block;
            margin-top: 8px;
        }
        .form_submit {
            margin-bottom: 16px;
        }
    }
    .customChecker.product_check label{
        cursor: pointer;
    }
    .nrArticlesSelected {
        font-family: $secondaryFont;
        font-size: 13px;
        text-transform: uppercase;
        line-height: $small_txt_lh;
    }

    .cta-wrapper,
    .share_wl {
        display: flex;
        margin-bottom: 20px;

        .cta-modify a span,
        .cta_add_to_basket,
        .share_wl_title {
            font-size: 11px;
            height: 13px;
            font-family: $secondaryFont;
            position: relative;
            display: block;
            cursor: pointer;

            &::after {
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                bottom: -3px;
                left: 0;
                background-color: black;
            }
        }
        .cta-remove {
            display: block;
            margin-left: auto;

            .remove::after {
                content: "x";
                width: 100%;
                font-family: $secondaryFont;
                height: 13px;
                bottom: 0;
                font-weight: 500;
                left: auto;
                right: 0;
            }
        }
        .cta_add_to_basket {
            margin-right: 40px;
        }
    }
    .nrArticlesSelectedWrapper {
        font-size: 13px;
        margin-bottom: 48px;
    }
    .nrArticlesSelected {
        margin-bottom: 40px;
    }
    .wish_liste_product {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .product_ctn {
            border: 1px solid #898989;
            max-width: 347px;
            width: 100%;
            height: 200px;
            padding: 24px 24px 24px 32px;
            margin-bottom: 40px;
            position: relative;

            &.checked {
                border-color: #000;
            }
            &:nth-child(2n+1) {
                margin-right: 32px;
            }

            .checkbox_select_product {
                display: none;
            }
            .customChecker  {
                position: absolute;
                left: -15px;
                top: 50%;
                transform: translate(50%, -50%);
                label {
                    height: 15px;
                    width: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #898989;
                    background-color: #fff;

                    div {
                        height: 9px;
                        width: 9px;
                    }
                }
                .checkbox_select_product:checked + label {
                    border: 1px solid #000;
                    background-color: #fff;

                    div {
                        background-color: $primaryColor;
                    }
                }
            }

            .product-link {
                position: relative;
                display: flex;
                align-items: center;
                height: fit-content;

                .photo_product {
                    margin-right: 25px;
                }

                .title {
                    font-family: $secondaryFont;
                }

                .bloc_price {
                    margin: 10px 0;
                    display: flex;

                    .old_price {
                        color: $grey;
                        position: relative;
                        margin-left: 8px;

                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 0;
                            width: 100%;
                            height: 1px;
                            background-color: $grey;
                        }
                    }
                }

                .color,
                .bloc_price,
                .shoesize,
                .title {
                    font-weight: 500;
                    font-size: 13px;
                }

                .shoesize {
                    margin-bottom: 3px;
                }
            }
        }
    }

    // Hide add to basket cta when no stock left
    .no_stock_left {
        .cta_add_to_basket {
            display: none;
        }
    }

    .report.error {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 11px;
        text-align: center;
        width: 120px;
        padding: .5rem;
        background-color: rgba(255,255,255,.5);
    }
}

.wishlist #abox {
    width: 668px;
}

.order .wrapper_content_cmd_detail .cart_detail_box {
    margin-top: 15px;
}

body.body_login {
    .w-oneclick-connect {
        #googleConnect {
            width: 66.36px;
            height: 36px;
            background: transparent;
            border-bottom: 1px solid $black;
            margin: 0 15px 0 0;

            div:first-child div:first-child div:first-child {
                width: 66.36px;
                height: 36px;
            }

            #googleConnectLabel {
                font-size: 13px;
                text-transform: uppercase;

                &:before {
                    display: none;
                }
            }
        }
    }
}
