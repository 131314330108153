@import '../var/colors';
@import '../var/fonts';
@import '../components/reset';

.page_text{
    font-family: $primaryFont;
    max-width: 1720px;
    margin:  0 auto;

    em {
        display: inline-block;
        padding: 25px 0 10px 0;
        
        u {
            text-decoration: none;
        }
    }

    .content_sat{
        max-width: 1350px;

        .content {
            font-size: 13px;
        }
    }

    h2 {
        color: $black;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .title {
        margin-top: 10px;
        padding-top: 16px;
        text-transform: uppercase;
        & > span {
            font-size: 23px;
        }
    }

    .row {
        margin-bottom: 25px;
    }

    #breadcrumbs {
        margin-bottom: 40px;
    }
}

.satellite_breadcrumbs_wrapper {
    display: none;
    padding-top: 8px;
    height: 60px;
    
}
@media screen and (max-width: 1720px) {
    .page_text {
        padding: 0 32px;
    }

    .wrapper_content_sat .breadcrumbs{
        padding: 16px 0 0;
    }
}