.bandeau_container,
#popup_savoir_plus{
    display: none;
}

.body_login .lightbox.actif {
    transition: none;
}

.newlogin_page{
    display: flex;
    justify-content: center;
    font-family: $primaryFont;
    padding-top: 0;


    .login_wrapper {
        margin-top: 123px;
        width: 554px;
        text-align: start;
        font-size: .8125rem;
        padding-top: 0;

        .login_bloc {
            margin: 0;
            width: 100%;
            align-items: flex-start;
            border-bottom-color: black;
            line-height: $small_txt_lh;

            .title {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-bottom: 24px;

                h3 {
                    text-align: start;
                    font-size: 15px;
                    font-family: $secondaryFont;
                    font-weight: $medium;
                    letter-spacing: 0.02em;
                    margin-top: 0;
                }

                h4 {
                    letter-spacing: normal;
                    font-size: 13px;
                    font-family: $secondaryFont;
                    font-weight: $medium;
                    letter-spacing: 0.02em;
                    margin-bottom: 10px;
                }

                .subtitleLogin, .subtitle {
                    text-align: start;
                    font-family: $primaryFont;
                    font-weight: $medium;
                    font-size: 13px;
                    line-height: $small_txt_lh;

                    span {
                        display: block;
                    }
                }

                &.my_account {
                    display: none;
                }
            }

            .w-oneclick-connect {
                display: flex;
                width: 100%;

                .w-social-btn {
                    margin-right: 15px;
                    padding: 3px 5px;
                    height: auto;
                    display: block;
                    text-transform: uppercase;
                    width: auto;
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid $primaryColor;

                    > div {
                        display: none;
                    }
                    > span {
                        display: block;
                        font-size: 13px;
                    }
                }
            }

            aside.login_protection_donnees_msg {
                display: block !important;

                p {
                    margin-top: 32px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                }
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        .login_form_wrapper form {
            width: 100%;
            font-size: 0.625rem;
            align-items: flex-start;

            .w-submit-button {
                @extend .primary;
            }
        }
        .w-form-line a{
            color: $primaryColor;
            border-bottom-color: $primaryColor;
        }
        .w-lines-group .optin_container {
            .w-line-label, .w-input, .w-input-element,
            .w-input-label span, .info_site a{
                color: $primaryColor;
            }

            .info_site {
                margin-top: 10px;

                a{
                    border-bottom-color: $primaryColor;
                }
            }
        }
        .w-submit {
            width: max-content;
        }
    }
    .w-client-form .w-lines-group {

        .w-fieldset-label {
            margin: 0;
            font-size: 13px;
            margin-bottom: .8rem;
        }
        .optin_container {
            width: 80%;
            flex-direction: row;
            justify-content: flex-start;

            > div.w-form-line {
                width: auto;
                &:first-of-type {
                    margin-right: pxToVw(40, 1920);
                }

                > label.w-line-label {
                    min-width: auto;
                    margin-right: 7px;
                }

                .w-line-label,
                label p > span{
                    font-family: $primaryFont;
                    font-size: 13px;
                }
            }

            .knowMoreOptin {
                display: none;
            }
        }
    }

    fieldset + div:nth-child(6){
        display: none;
    }

    #resetPass .w-lostpass-form {
        .w-submit, .w-submit-button {
            @extend .primary;
            max-width: none;

        }
        .w-btn-wrapper {
            width: max-content;
        }
    }

    .w-submit-button span{
        height: auto;
    }
}

.login_form_wrapper .w-form-line > .w-pwd-reset {
    margin: 0;
    font-weight: 500;
    font-family: $secondaryFont;
    font-size: 11px;
    line-height: 13px;
    text-align: start;
}

.w-client-form .w-lines-group .optin_container .w-input-note.w-input-error {
    text-align: start;
}

.vp {
    padding: 0 2rem 0 2rem;
    @media (max-width: 1024px) {
        .bandeau_connexion_style {
            max-width: calc(100vw - 64px);
        }
    }

    .bandeau_connexion_style {
        margin: auto;
        display: grid;
        gap: 80px;
        grid-template-columns: repeat(2, 1fr);
        max-width: 1134px;
        padding-top: 85px;

        .login_wrapper {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .ill_img, .watched, .login_wrapper{
            width: 100%;
        }
    }


    #resetPass .w-lostpass-form {
        .w-submit, .w-submit-button {
            @extend .primary;
            max-width: none;

        }
        .w-btn-wrapper {
            width: max-content;
        }
    }

    .w-submit-button span{
        height: auto;
    }
}

button.w-submit-button.w-loader > span {
    display: none;
}

.script_renderpwdforget #site_global_wrap,
.body_login #site_global_wrap {
    display: flex;
    justify-content: center;

    .wrapper_moncompte_main {
        width: 100%;
    }

    .breadcrumbs {
        display: none;
    }

    > div {
        text-align: start;
        font-size: .8125rem;
        padding-top: 0;

        input {
            border: 1px solid #b1b1b1;
            border-radius: 0;
        }

        .w-has-error {
            input {
                border-color: #ff6d6d;
            }
        }

        .title_infos {
            text-align: start;
            font-size: 15px;
            font-family: $secondaryFont;
            font-weight: $medium;
            letter-spacing: 0.02em;
            margin-top: 0;
            text-transform: uppercase;
            margin-bottom: 24px;
        }

        .w-btn-wrapper {
            width: auto;
        }

        .w-visibility-toggle {
            border: none;
        }
    }
    .wrapper_moncompte.content_password {
        margin-top: 123px;
        width: 554px;
    }

}

.contentAlert {
    color: #FF0000;
    line-height: 20px;
    margin: 0px 0px 10px 0px;
    font-size: 13px;
}