/***** Button *****/
.form_submit,
.btn_container {
    position: relative;
}

.w-submit-button,
.w-loader {
    background-color: $primaryColor;

    span {
        background-color: $primaryColor;
    }
}

.button,
.w-submit-button,
button.w-submit-button {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 40px;
    font-size: $small_txt;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 15px;
    outline: none;
    border-style: unset;
    border: 1px solid $primaryColor;
    box-sizing: border-box;
    transition: all .3s ease;
    padding: 0;

    &:not(.w-loader) {
        position: relative;
    }

    &.w-loader,
    &+.loader,
    &.loader,
    .loader {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 1px solid $primaryColor;
        box-sizing: border-box;
        font-size: 0;

        span {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 0;
        }

        &.loading {
            opacity: 1;
            z-index: 2;
        }
    }

    &.dark,
    &.primary {
        font-family: $secondaryFont;
        color: $white;
        background-color: $primaryColor;

        span {
            background-color: $primaryColor;
            color: $white;
        }

        &:hover {
            font-family: $primaryFont;
            background-color: $white;
            color: $primaryColor;

            span {
                background-color: $white;
                color: $primaryColor;
            }
        }


        /* Loader */
        &.w-loader,
        &+.w-loader,
        &+.loader,
        &.loader,
        .loader {
            background-color: $white;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../svg/three_dots.svg);
            background-size: 18%;

            span {
                background-color: $white;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../svg/three_dots.svg);
                background-size: 18%;
            }
        }
    }

    &.alert {
        background-color: $white;
        color: $red;
        border: 1px solid $red;

        &:hover {
            background-color: $red;
            color: $white;
            border: 1px solid $red;
        }
    }


    &.light,
    &.secondary {
        font-family: $primaryFont;
        background-color: $white;
        color: $primaryColor;
        letter-spacing: 0;

        span {
            background-color: $white;
            color: $primaryColor;
        }


        &:hover {
            font-family: $secondaryFont;
            background-color: $primaryColor;
            color: $white;


            span {
                background-color: $primaryColor;
                color: $white;
            }
        }


        /* Loader */
        &.w-loader,
        &+.w-loader,
        &+.loader,
        &.loader,
        .loader {
            background-color: $primaryColor;
            background-image: url(../svg/three_dots_w.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 18%;

            span {
                background-color: $primaryColor;
                background-image: url(../svg/three_dots_w.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 18%;
            }
        }
    }

    >span {
        transition: all .3s ease;
    }

    span {
        font-size: $small_txt;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

// ACHAT EXPRESS WHITE COLOR
.bloc_add_color {
    .button.dark {
        @extend .light;
    }
}

.bloc_add_alert {
    position: relative;
    .button {
        color: $red;
        border: 1px solid $red;
        background-color: transparent;
    }
}

/* Loader search */
#search_results .loader span {
    display: block;
    width: 100px;
    height: 50px;
}
