// Page Inactive
.home-module.signup-module {
    margin: 0rem 0rem 5.938rem 0rem;

    .home-columns-container {
        padding: 5.313rem 20.833vw 0rem 20.833vw;

        @media screen and  (max-width: 1700px) {
            padding: 3.6rem 0 0 5rem;
        }
    }

    .home-column {
        display: flex;
        width: 58.281vw;
        max-width: 1119px;
        margin: 0 auto;

        @media screen and (max-width: 1700px) {
            padding-right: 5rem;
            width: 100%;
        }

        .home-module-contents {
            padding: 6.6rem 0 0 5rem;

            @media screen and  (max-width: 1700px) {
                padding: 1.6rem 0 0 5rem;
            }

            .home-module-title {
                font-family: $secondaryFont;
                font-weight: $medium;
                font-size: 23px;
                line-height: 28px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: $black;
                margin-bottom: 8px;
            }

            .home-module-subtitle {
                font-family: $secondaryFont;
                font-weight: $medium;
                font-size: 13px;
                line-height: $small_txt_lh;
                color: #000000;
                margin-bottom: 8px;
            }

            .home-module-text {
                margin-bottom: 27px;
                font-family: $primaryFont;
                font-weight: $medium;
                font-size: 13px;
                line-height: $small_txt_lh;
                color: #000000;
            }

            .w-newsletter-form {
                width: 27.917vw;
                max-width: 539px;

                .iAmLabel {
                    display: none;
                }

                .w-email-input.w-nonempty.w-focused, .w-email-input.w-nonempty.w-has-error {

                    .w-input-label {
                        top: 61%;
                    }
                }

                .w-input-label span {
                    font-family: 'Arial';
                    font-weight: $regular;
                    font-size: 14px;
                    line-height: 21px;
                    color: #868686;
                }

                .w-form-line.news_civilite {
                    margin-bottom: 1.4rem;
                }

                .news_civilite .w-radio-group .w-radio-input .w-input-label span {
                    color: white;

                    &::before {
                        content: "Monsieur";
                        display: block;
                        position: absolute;
                        color: black;
                    }
                }

                .news_civilite .w-radio-group .w-radio-input:first-of-type .w-input-label span {
                    color: white;

                    &::before {
                        content: "Madame";
                        display: block;
                        position: absolute;
                        color: black;
                    }
                }
                
                .w-input-element:not([type=radio]):not([type=checkbox]), .w-newsletter-form input[type=email].w-input-element {
                    border-radius: 0;
                }

                div.w-form-line.w-submit {
                    position: inherit;
                    width: 150px;
                    max-width: 150px;
                }

                .w-submit-button {
                    width: 100%;
                }

                .w-input-note.w-input-error {
                    background-color: white;                }
            }
        }

        .home-module-module-img {
            width: 500px;

            img {
                width: 100%;
                height: auto;
            }
        }


    }
}

// Site map
.footer_sitemap {

    .page_wrapper {
        max-width: $wrapperMediumXL;
        width: 100%;
        margin: 0 auto;

        @include mediumxlScreen {
            padding: 0 $min_pad;
        }
    }

    .satellite_title {
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 23px;
        line-height: 28px;
        text-transform: uppercase;
        color: #000000;
        padding-top: 1rem;
    }

    .breadcrumbs {
        padding: 8px 0 0 0;
    }

    .satellite_content {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        gap: pxToVw(4,1920);
        margin: 0 0 6.25rem 0;
    }

    .block_title_and_section {
        margin: 5rem 0rem -1rem 0;
    }

    .big_title_charte {
        margin: 0 0 2.688rem 0;
        text-transform: uppercase;
        max-width: 49%;

        span {
            font-family: $secondaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: $small_txt_lh;
            text-transform: uppercase;
            color: #000000;
        }
    }

    .new_sitemap_subcategory h3 {
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 13px;
        line-height: $small_txt_lh;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    .site_map_ul li {
        font-family: $primaryFont;
        font-weight: $medium;
        font-size: 13px;
        line-height: $small_txt_lh;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 1rem;
    }

    .sitemap_section {
        display: flex;
        gap: pxToVw(8,1920);
        flex-direction: column;
    }
}

.en {

    .news_civilite .w-radio-group .w-radio-input .w-input-label span {
        color: white;

        &::before {
            display: none !important;
        }
    
        &::after {
            content: "Mr";
            display: block;
            position: absolute;
            top: 0;
            color: black;
        }
    }

    .news_civilite .w-radio-group .w-radio-input:first-of-type .w-input-label span {
        color: white;

        &::before {
            display: none !important;
        }

        &::after {
            content: "Mrs";
            display: block;
            position: absolute;
            top: 0;
            color: black;
        }
    } 
}

.misc_cookiesinfo {
    #appContainer {
        .main_wrapper {
            .wrapper_content_sat {
                h1.title {
                    text-transform: lowercase;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    #cookiesLightbox {
        &.displayCookies {
            display: block!important;
        }

        &.hideCookies {
            display: none!important;
        }

        .w-form-line.w-submit button.w-loader.hideLoader {
            z-index: -1;
            opacity: 0;
        }
    }
}