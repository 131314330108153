.w-input-element {
    box-sizing: border-box;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
}

.w-input-element:not([type=radio]):not([type=checkbox]),
.w-input-container .w-dropdown {
    border: 1px solid #B1B1B1;
    border-radius: 0;
    font-size: 14px;
    & + .w-input-label {
        color: #868686;
        font-size: 14px;
        font-weight: 400;
    }
}

.w-tel-input .w-input-label{
    color: #868686;
}

[type=radio].w-input-element {
    & + .w-input-label {
        font-size: 14px;
        font-weight: 400;
    }
}


.w-input.w-nonempty:not([type=radio]):not([type=checkbox]),
.w-date-input {
    .w-input-label {
        font-size: 12px;
        color: #868686;
    }
}
.w-radio-input [type="radio"] + .w-input-label {
    > span {
        margin-left: .5em;
    }
    &::before {
        box-sizing: border-box;
        border: 1px solid #B1B1B1;
        width: 15px;
        height: 15px;
    }
}
.w-has-error, .w-has-error {
    input {
        border-color: #ff6d6d;
        &[type="radio"] + .w-input-label {
            &::before {
                border-color: #ff6d6d;
            }
            span {
                color: #ff6d6d;
            }
        }
    }
}
.w-input.w-tel-input .selected-flag {
    border: none;
    border-right: 1px solid #B1B1B1;
    background-color: transparent;
    &::after {
        border-top: 0.35em solid #868686;
    }
}

.img_error,
.valid_form {
    display: none;
}

.w-input-note.w-input-error {
    text-align: start;
}

.newlogin_page .w-newlogin-form .optin_container .w-input-note.w-input-error {
    text-align: left;
}

.w-visibility-toggle {
    border: none;
}

.w-form-line.w-submit {
    display: flex;
    justify-content: flex-start;
    max-width: max-content;

    .w-submit-button {
        @extend .primary;

        &.w-loader:hover span {
            display: none;
        }
    }
}

.w-input-container {
    .w-value {
        color: $primaryColor;
    }

    .w-dropdown::after{
        border-top-color:#868686;
    }
}

.wrapper_form .update_success {
    margin-bottom: 20px;
}



// ALERT STOCK

.w-alertestock-form {
    display: none;
    width: 100%;

    #mail_alerte_stock,
    #mail_alerte_stock:focus,
    #mail_alerte_stock:target {
        font-family: $primaryFont;
        font-size: $small_txt;
        box-shadow: none;
        outline: none;
        border: 1px solid $red;
    }

    .w-input-label {
        text-transform: uppercase;
        left: .85714em;
    }

    .w-form-line.w-submit {
        max-width: max-content;
        position: absolute;
        top: 50%;
        right: .833vw;
        transform: translateY(-50%);
    }
    .w-submit-button {
        position: relative;
    }
    .w-submit-button,
    .w-submit-button.w-loader {
        width: auto;
        font-family: $primaryFont;
        color: $red !important;
        background-color: $white!important;
        border: 0!important;

        span {
            color: $red !important;
            background-color: $white!important;
            border: 0 !important;
        }

        &:hover {
            background-color: $white!important;
            color: $red !important;

            span {
                background-color: $white!important;
                color: $red !important;
            }
        }
    }
    .w-submit-button.w-loader {
        width: 100%;
        height: 100%;
    }
}

.w-coupon-form {
    width: 100%;

    #input_codePromo,
    #input_codePromo:focus,
    #input_codePromo:target {
        font-family: $primaryFont;
        font-size: 14px;
        box-shadow: none;
        outline: none;
        border-radius: 0;
        border: 1px solid $primaryColor;
    }

    .w-input-label {
        left: .85714em;
    }

    .w-form-line.w-submit {
        max-width: max-content;
        position: absolute;
        top: 50%;
        right: .833vw;
        transform: translateY(-50%);
    }

    .w-submit-button {
        position: relative;
    }

    .w-submit-button,
    .w-submit-button.w-loader {
        width: auto;
        font-family: $primaryFont;
        color: $primaryColor !important;
        background-color: $white !important;
        border: 0 !important;

        span {
            color: $primaryColor !important;
            background-color: $white !important;
            border: 0 !important;
        }

        &:hover {
            color: $primaryColor !important;
            background-color: $white !important;

            span {
                color: $primaryColor !important;
                background-color: $white !important;
            }
        }
    }

    .w-submit-button.w-loader {
        width: 100%;
        height: 100%;
    }

    .w-form-line.w-submit .w-submit-button {
        height: auto;
        width: auto;
    }
}

#adresseForm .w-form-line { 
    &.addrDepartment {
        display: none;

        .addrDepartmentSelector {
            height: 48px;
        }
    }

    .addrCountry {
        height: 48px;
    }
}