/* COMMON **********************************************************************************************/
/*******************************************************************************************************/

#site_head_wrap {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: $white;
}

#site_global_wrap {
    margin: $headerHeightBig auto 0
}

// Used in search_results in header_right_side -->
.glmt_bfr {
    margin-right: 3px;

    &::before {
        content: "“";
    }
}

.glmt_aft {
    margin-left: 3px;

    &::before {
        content: "”";
    }
}

.glmt_bfr,
.glmt_aft {
    display: inline-block;
    position: relative;
    width: 8px;
    height: 8px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        font-size: 20px;
    }
}


// Rollovers logic
.header_item.with_rollover {

    &.is_logged.wishlist,
    &.is_logged.account,
    &.my_basket {
        &:hover {
            .rollover_bloc {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    &.active_rollover {
        .rollover_bloc {
            transform: translateX(0);
            opacity: 1;
        }
    }

    // Let the element underlined on active_rollover class
    &.active_rollover {
        .to_underline {
            &::after {
                width: 100%;
                left: 0;
                right: 0;
            }
        }
    }
}

#site_head_wrap.sticky {
    .rollover_bloc {
        height: calc(100vh - #{$headerHeightSmall});
    }
}

.rollover_bloc {
    // ABSOLUTE
    position: absolute;
    right: 0;
    top: 100%;
    transform: translateX(300%);
    background: $white;
    color: $primaryColor;
    width: 465px;
    height: calc(100vh - #{$headerHeightBig});
    padding: 40px 64px;
    transition: all .3s ease-in-out;
    z-index: 9;
    box-sizing: border-box;
    cursor: initial;
    opacity: 0;

    //Position .rollover_bloc properly for screens wider than 1920px
    @media only screen and (min-width: 1921px) {
        right: calc(50vw - 968px);
    }

    @include mediumxlScreen {
        width: 400px;
    }

    // WISHLIST
    &#show_top_wish {
        display: flex;
        flex-direction: column;

        .products_listing {
            flex-grow: 1;
        }


        .see_wishlist {
            margin-top: 14px;
        }
    }

    &#show_top_wish,
    &#show_top_cart {
        display: flex;
        flex-direction: column;
    }

    // Title
    .wrap_title {
        display: flex;
        align-items: flex-end;
        padding-bottom: 32px;
    }

    .title_panier {
        font-family: $secondaryFont;
        font-size: $small_txt;
        text-transform: uppercase;
        line-height: 15px;
        padding-right: 5px;
    }

    // Nb products in wish/basket
    .nb_products {
        display: flex;
        font-size: 14px;
        font-family: $secondaryFont;
        font-weight: $regular;

        &::before {
            content: "(";
            margin-right: 1px;
        }

        &::after {
            content: ")";
            margin-left: 1px;
        }

        &::before,
        &::after {
            display: inline-block;
            width: auto;
            height: auto;
        }
    }

    // Cart Total
    .wrap_tot_panier {
        font-size: $small_txt;
        margin-top: 16px;

        .finalize_order {
            >.form_submit {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    .cart_panier_total {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 40px;

        .titre_tot {
            font-family: $secondaryFont;
            text-transform: uppercase;
        }
    }

    // Submits
    .btn_container {
        max-width: 250px;
        margin: 0 auto;
    }


    // No products bloc
    .rollover_empty {
        font-size: $small_txt;
    }
}

.to_underline_with_container {
    >.underline_target {
        position: relative;

        &::after {
            z-index: 20;
            content: "";
            position: absolute;
            bottom: 0;
            height: 1px;
            right: 0;
            width: 0;
            background-color: var(--txtcolor, $primaryColor);
            transition: $all_transition;
        }
    }

    &:hover {
        >.underline_target::after {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}

.to_underline {
    position: relative;

    &::after {
        z-index: 20;
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        right: 0;
        width: 0;
        background-color: $primaryColor;
        transition: $all_transition;
    }

    &:hover {
        &::after {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}

/* GLOBAL *************************************************************/
/**********************************************************************/

// PREHEADER
.bandeau_header {
    font-size: $small_txt;
    width: 100%;
    height: $preheader_height;

    .close_delais {
        display: none;
    }

    >.swiper-container {
        height: 100%;
    }

    .swiper-slide {

        >div {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
}

// HEADER
.head_top {
    &::before {
        opacity: 0;
        z-index: -1;
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba(3, 3, 3, .4);
        top: 0;
        left: 0;
        transition: all .3s ease-out;
    }
}

.header {
    display: flex;
    max-width: $wrapperMediumXL;
    width: 100%;
    height: $headerHeightSmall;
    margin: 0 auto;
    background-color: $white;

    @include mediumxlScreen {
        padding: 0 $min_pad;
    }

    //Recherche trigger + input
    .header_item.search {
        margin-right: 267px;

        @include largeScreen {
            margin-right: 0.906vw;
        }
    }

    #trigger_recherche {
        cursor: pointer;
        position: relative;
        display: block;
        width: 400px;
        height: 20px;
        padding: 0 16px 4px 1px;
        font-size: $small_txt;
        border-bottom: 1px solid $primaryColor;

        // Picto loupe
        >.bfr {
            position: absolute;
            top: 0;
            right: 0;
        }

        @include largeScreen {
            width: 20.833vw;
        }
    }

    @media screen and (max-width: 1400px) {
        #trigger_recherche {
            width: 18vw;
        }
    }

    @media screen and (max-width: 1140px) {
        .header_item.search {
            margin-right: 20px;
        }

        #trigger_recherche {
            font-size: 0;
            width: auto;
            border: 0;
            padding: 0;

            >.bfr {
                position: initial;
                top: initial;
                right: initial;
            }
        }
    }

    .wrap_search_field {
        position: absolute;
        left: 0;
        top: 100%;
        transition: transform .2s ease-in-out, opacity .6s ease;
        transform: translateY(-200%);
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: $white;
        z-index: -1;
        opacity: 0;
        display: flex;
        flex-direction: column;
        padding: 1.875vw 0 2.083vw;

        &.actif {
            opacity: 1;
            transform: translateY(0);
        }

        @include mediumxlScreen {
            padding: 5vw $min_pad;
        }

    }

    #search_results {
        padding: .7rem 2rem 0;

        .search_suggestion:not(.search_loader) {
            display: flex;
            align-items: flex-end;
            max-width: 50%;
            font-family: $secondaryFont;
            font-size: $small_txt;
            padding: .25rem 0;
            line-height: 1;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: .5rem;
            }

            .suggestion_term,
            .suggestion_nb_results {
                display: flex;
                align-items: flex-start;
            }

            .suggestion_term {
                text-transform: uppercase;
            }

            .suggestion_nb_results {
                margin-left: 1rem;

                &::before {
                    content: "(";
                }

                &::after {
                    content: ")";
                }

                &::before,
                &::after {
                    display: inline-block;
                    width: auto;
                    height: auto;
                }

                >span {
                    margin-left: 5px;
                }
            }
        }
    }

    #form_recherche,
    #search_results {
        max-width: $wrapperMediumXL;
        width: 100%;
        margin: 0 auto;
    }

    .wrap_field {
        position: relative;
        max-width: 400px;
        width: 100%;
        border-bottom: 1px solid $primaryColor;
        padding: 0 10px 10px 0;
        margin: 0 auto 0 0;

        &.searching {
            >.bgSprite {
                &.bfr {
                    &.search {
                        transform: translateY(-50%) scale(0);
                    }

                    &.cross {
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }

        >.bgSprite {
            position: absolute;
            top: calc(50% - 5px); // wrapper's bottom padding
            right: 10px; // wrapper's right padding
            width: 18px;
            height: 18px;
            transform: translateY(-50%);
            cursor: pointer;

            &.bfr {
                transition: transform .2s ease;

                &::before {
                    width: 100%;
                    height: 100%;
                }

                &.search {
                    transform: translateY(-50%) scale(1);
                }

                &.cross {
                    transform: translateY(-50%) scale(0);
                }
            }
        }
    }

    // search input
    #search_home {
        font-family: $secondaryFont;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 36px;
        border: 0;
    }

    // LOGO
    .header_title {
        font-size: 0;
        display: block;
        width: 203px;
        height: 40px;
        background: url('../svg/logo_sp.svg') no-repeat center;
        background-size: contain;
        margin: 30px 4.167vw 30px 0;
    }

    .header_rightSide {
        display: flex;

        >.content_block {
            display: flex;
        }
    }

    // HEADER RIGHT SIDE **************************
    .header_item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            &:not(.switchLang):not(.search) {
                margin-right: 16px;
            }
        }


        &.is_logged.wishlist,
        &.is_logged.account,
        &.my_basket {
            cursor: pointer;
        }

        // Reposition underline
        .to_underline::after {
            bottom: -4px;
        }


        .bfr.bgSprite {
            &.cart {
                &::before {
                    width: 19px;
                    height: 19px;
                }
            }

            &::before {
                width: 17px;
                height: 17px;
            }
        }
    }

    // SWITCHLANG
    .switchLang {
        font-size: $small_txt;
        font-weight: $medium;
        text-transform: uppercase;
        margin-right: 37px;
        line-height: 15px;

        .switchLang_innerWrapper {
            position: relative;

            &:hover {
                .switchLangBtn {
                    opacity: 1;
                    z-index: 1;
                }

                .currentLang .bgSprite.bfr::before {
                    transform: rotate(180deg);
                }
            }
        }

        .switchLangBtn {
            position: absolute;
            opacity: 0;
            z-index: -1;
            top: 100%;
            left: 50%;
            overflow: hidden;
            padding: .5rem 1rem;
            background-color: $white;
            transition: $all_transition;
            transform: translateX(-50%);
        }

        .currentLang {
            display: block;
            position: relative;
            z-index: 2;
            background-color: $white;
            cursor: pointer;

            .bgSprite {
                position: absolute;
                top: 50%;
                left: 100%;
                width: auto;
                height: auto;
                transform: translateY(-50%);

                &.bfr::before,
                &.aft::after {
                    width: 8px;
                    height: 8px;
                    margin-left: 5px;
                    transition: $all_transition;
                }
            }
        }

        .lang_itm {
            display: block;
            padding: .3rem 0;
        }
    }

    // ACCOUNT
    .header_rollover_account {
        display: flex;
        flex-direction: column;
        padding: 40px 64px 40px 64px;

        #btn-disconnect,
        .loader {
            max-width: 250px;
            width: 250px;
        }

        #btn-disconnect {
            margin: 0 auto;
        }

        .loader {
            left: unset;
            right: 0;

            @media screen and (max-width: $wrapperMediumXL) {
                right: 50%;
                transform: translateX(50%);
            }
        }
    }

    .account_nav {
        font-size: $small_txt;
        line-height: 15px;
        text-transform: uppercase;
        flex-grow: 1;

        >.mon_compte_title {
            font-family: $secondaryFont;
            margin-bottom: 32px;
        }

        >.item {
            margin-bottom: 16px;

            &.actif {
                >a::after {
                    width: 100%;
                }
            }


            >a {
                position: relative;

                &::after {
                    z-index: 20;
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    right: 0;
                    width: 0;
                    background-color: $primaryColor;
                    transition: $all_transition;
                }

                &:hover {
                    &::after {
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
    }



}

// Rollover basket
#wishlist_list,
#cart_list {
    flex-grow: 1;

    .pricetag {
        line-height: 13px;

        &.crossed {
            position: relative;
            margin-right: 8px;

            &::after {
                bottom: 5px;
            }
        }
    }

    .holder_scrollable {
        max-height: 50vh;
        overflow: auto;

        @media screen and (max-height: 850px) {
            max-height: 45vh;
        }
        @media screen and (max-height: 730px) {
            max-height: 40vh;
        }
        @media screen and (max-height: 680px) {
            max-height: 35vh;
        }
    }

    .os-content {
        padding-right: 5px !important;
    }

    .product-link {
        display: flex;
        margin-bottom: 20px;
        position: relative;
    }

    .cart_link {
        width: 120px;
        display: block;
        margin-right: 32px;

        .cart_thumb_holder {
            width: 120px;

            img {
                width: 120px;
                height: 120px;
            }
        }
    }

    .cart_detail_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: calc(100% - 162px);
    }

    .cart_product {
        .title_holder {
            margin-bottom: 8px;
        }

        .title {
            display: block;
            // full width without cta remove
            max-width: 100%;
            font-family: $secondaryFont;
            font-size: 11px;
            line-height: 13px;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .subtitle {
            display: none;
        }

        .wrapper_price {
            display: flex;
            font-size: 11px;
            line-height: 13px;
            margin-bottom: 8px;
        }

        .sub {
            font-size: 11px;
            line-height: $small_txt_lh;

            .color_bullet {
                display: none;
            }
        }

        .sub_quantity {
            margin-bottom: 8px;
        }

    }

    .product_ctas_container {
        line-height: 1;

        .modify {
            display: none;
        }

        .remove_prod,
        .remove_top_wishlist {
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            font-size: 0;
            z-index: 2;
            cursor: pointer;

            &::before {
                width: 100%;
                height: 100%;
            }
        }

        .addToWishlistButton,
        .addToCartlistButton {
            font-family: $secondaryFont;
            font-size: 11px;
            line-height: 13px;
            padding-bottom: 3px;
            border-bottom: 1px solid $primaryColor;
            cursor: pointer;
        }
    }
}

// CART
#cart_top {
    .link_item_cart {
        position: relative;
    }

    .nb_products_cart {
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-style: initial;
    }
}



/* MENU ************************************************************************************************/
/*******************************************************************************************************/

.header_main_wrapper {
    display: flex;
    flex-grow: 1;

    >.main_menu_itm {
        display: flex;
        align-items: center;
        font-size: $small_txt;
        text-transform: uppercase;
        font-weight: $medium;
        line-height: $small_txt_lh;
        padding: 0 1.344rem;
        
        @media screen and (max-width: 1500px) {
            font-size: 12px;
            padding: 0 1rem;
        }

        &:hover {
            .submenu_wrapper {
                opacity: 1;
                pointer-events: initial;
                cursor: initial;
            }
        }

        &.no_pointer_event {
            & > a.has_submenu {
                pointer-events: none;
            }
        }

        // current class is used upon categories page
        &.current,
        &.hovered {
            .entry {
                color: var(--txtcolor, #000000);
            }
        }

        .entry {
            display: block;
            padding: 3px 3px 4px;
            transition: $all_transition;

            &.current {
                &.underline_target:after {
                    width: 100%;
                }
            }
        }

        // Dynamic colors on underlined elements in menu
        &.to_underline::after,
        .to_underline::after,
        &.to_underline_with_container .underline_target:after,
        .to_underline_with_container .underline_target:after {
            background-color: var(--txtcolor, $primaryColor);
        }


        // WP-28037 - disabling clicking on "The holiday collection" menu item
        &#categ_15 > a,
        a[href="/the-holiday-selection.html"],
        a[href="/la-selection-vacances.html"] {
            pointer-events: none;
            user-select: none;
        }
    }

    .submenu_wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-height: 405px;
        transition: all .3s ease-out;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        background-color: $white;
        font-size: $small_txt;
        line-height: $small_txt_lh;

        @media screen and (max-height: 750px) {
            min-height: unset;
        }

        .ss_menu_categ_wrapper {
            display: flex;
            max-width: $wrapperMediumXL;
            width: 100%;
            margin: 0 auto;
            padding: 40px 0 80px;

            @media screen and (max-width: $wrapperMediumXL) {
                padding: 40px $min_pad 80px;
            }
        }

        // Subcat list
        .menu_ss_cat_list {
            margin-right: 7.813vw;

            @media screen and (max-width: 1024px) {
                margin-right: 3.9065vw;
            }

            &:last-child {
                margin-right: 3vw;
            }
        }

        // Subcat item
        .ss_cat_item {
            font-family: $secondaryFont;

            .see_all {
                display: none;
            }
        }

        .ss_cat_item,
        .ss_cat_sub {
            margin-bottom: 16px;
        }

        .menu_img_push {
            max-width: 410px;
            margin: 0 0 0 auto;

            img {
                vertical-align: bottom;
                max-width: 100%;
            }
        }

        .push_link_cta {
            display: block;
            font-family: $secondaryFont;
            padding-top: 17px;
        }
    }
}