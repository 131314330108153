.home-module {
    margin: 0 auto;
    padding: 50px 0;

    &.cover-module {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}


//Cover Module
.cover-module {

    .img_cover {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .slide_video {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc(494 / 1010 * 100%);

        iframe {
            position: absolute;
            top: 0%;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .home-module-contents {
        position: absolute;
        top: 43.5%;
        left: 7.5vw;

        .home-module-title {
            margin-bottom: .5rem;
            font-family: $secondaryFont;
            font-weight: $medium;
            font-size: 30px;
            line-height: 36px;
            text-transform: uppercase;
            color: $black;
        }

        .home-module-subtitle {
            margin-bottom: 2.5rem;
            font-family: $primaryFont;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: $black;
        }

        .home-module-cta1, .home-module-cta2, .home-module-cta3, .home-module-cta4 {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: $small_txt_lh;
            text-transform: uppercase;
            color: $black;
            width: 240px;
            height: 40px;
        }

        .content-cta {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
    }
}

// Module Text
.text-module-1 {
    display: flex;
    width: 89.583vw;
    max-width: 1720px;

    .home-module-title {
        width: 22.708vw;
        text-align: left;
        text-transform: uppercase;
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 23px;
        line-height: 28px;
        text-transform: uppercase;
    }

    .home-module-subtitle {
        margin-top: 2.5rem;
    }

    .home-module-text {
        width: 66.875vw;
        max-width: 1284px;
        font-family: $primaryFont;
        font-weight: $medium;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
    }
}

// Module Multibloc
.multiple-bloc-1 {
    max-width: 89.583vw;
    position: relative;

    &:last-of-type {
        margin-bottom: 6.25rem;
    }

    .swiper-button-next {
        position: absolute;
        top: 45%;
        right: -3%;
        line-height: 17px;

        &.swiper-button-disabled {
            display: none;
        }

        &::before {
            content: "";
            display: block;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(1,6,6);
            width: 24px;
            height: 24px;
            color: black;
            transform: rotate(270deg);
            pointer-events: none;
        }
    }

    .swiper-button-prev {
        position: absolute;
        top: 45%;
        left: -3%;

        &.swiper-button-disabled {
            display: none;
        }

        &::before {
            content: "";
            display: block;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(1,6,6);
            width: 24px;
            height: 24px;
            color: black;
            transform: rotate(90deg);
            pointer-events: none;
        }
    }

    .home-module-title {
        margin-bottom: 1.75rem;
    }

    .home-color-text {
        margin-top: 1rem;
    }

    .title_2 {
        margin-bottom: .25rem;
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 16px;
        line-height: 24px;
        color: $black;
    }

    .home-module-text {
        font-family: $primaryFont;
        font-weight: $medium;
        font-size: 14px;
        line-height: 17px;
        color: $black;
    }

    // Image holder gets image size as padding-top to prevent jump effects
    .holder_image {
        position: relative;
        padding-top: calc(400 / 400 * 100%);
    }

    // Image inside the holder
    .img_slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        vertical-align: top;
        font-size: 0; // Avoid alt attribute to be displayed
    }
}

// Module images Boutique

#store_slider {
    height: 450px;

    .swiper-slide > .swiper-lazy {
        height: 100%;
        width: 100%;
    }
}
