$productTooltipWidth: auto;
$productTooltipBgColor: unset;
$productTooltipBorderColor: unset;
$productTooltipArrowColor: unset;
$productTooltipColor: $primaryColor;

.disabledBtnTooltip {
    display: none !important;
    position: absolute !important;
    top: unset !important;
    bottom: -8px !important;
    left: 0 !important;
    right: 0 !important;
    min-width: unset !important;
    padding: 0 !important;
    text-align: left !important;
    font-size: .813rem !important;
    border: none !important;
    color: $red !important;
    background-color: unset !important;

    &.actif {
        font-size: .813rem;
        font-weight: 300;
    }

    &::before {
        display: none !important;
    }
}