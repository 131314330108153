/******************************************************************************/
/* MEDIA QUERIES  */
/******************************************************************************/

@mixin largeScreen {
    @media screen and (max-width: $wrapperLarge) {
        @content;
    }
}

@mixin mediumScreen {
    @media screen and (max-width: $wrapperMedium) {
        @content;
    }
}

@mixin mediumxlScreen {
    @media screen and (max-width: $wrapperMediumXL) {
        @content;
    }
}

@mixin smallScreen {
    @media screen and (max-width: $wrapperSmall) {
        @content;
    }
}

@mixin tabletScreen {
    @media screen and (max-width: $wrapperTablet) {
        @content;
    }
}

/**
 * Calculate element size value in vw based on the px value on the mock up
 *
 * $elSize => element/space/margin size in px used on PSD or Figma
 * $viewport => Viewport width used on PSD or Figma mock-up
 */
@function pxToVw($elSize, $viewport) {

    @return ($elSize / $viewport) * 100vw;
}

/**
 * Exemple :
 *
 * font-size: pxToVw(16,640);
 */