/* WRAPPERS VARS */
$wrapperLarge : 1920px;
$wrapperMediumXL : 1720px;
$wrapperMedium : 1680px;
$wrapperSmall : 1280px;
$wrapperTablet : 1024px;

/* HEADER HEIGHT VARS */
$preheader_height : 40px;
$headerHeightSmall: 100px;
$headerHeightBig : calc(#{$preheader_height} + #{$headerHeightSmall});
$fixed_headerHeightSmall: 80px;
$fixed_headerHeightBig : calc(#{$preheader_height} + #{$fixed_headerHeightSmall});
$all_transition : all .3s ease;

$min_pad: 2rem;
$small_txt: 13px;
$small_txt_lh: 16px;


/* IMG FORMAT VARS */
$img_large  : 1780px;
$img_midlarge  : 564px;
$img_medium : 428px;
$img_midmedium : 250px;
$img_mini : 250px;
$img_rayon : 557px;


