#wrapper_contact {
    display: flex;
    min-height: 30vh;
    max-width: 1720px;
    margin: 0 auto;

    .wrapperContentFaqForm {
        display: flex;
    }
    .w-textarea .w-input-element {
        outline: none;
        height: 157px;
    }
    .w-input.w-dropdown {
        height: 48px;
    }
    .file-list {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 4px;
    }
    #reset_file1 {
        display: none !important;

        &::after {
            transform: rotate(-45deg) translate(-1px, -3.5px);
        }
        &::before {
            transform: rotate(45deg) translate(-3.5px, 0.5px);
        }
    }
    .required-fields {
        bottom: 72px;
        font-size: 11px;
    }
    #mes_err_contact {
        margin-bottom: 10px;
        font-size: 12px;
    }
    .file-input-row {
        margin-top: 0;
        width: fit-content;
        height: 16px;
        margin-bottom: 13px;

        .ajout_pj_label {
            border: none !important;
            font-family: $secondaryFont;
            font-weight: 500;
            font-size: 11px; 
            line-height: 13px;
            text-transform: initial;
            position: relative;
            height: 33px !important;

            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: $primaryColor;
                position: absolute;
                bottom: 20px;
                left: 0;
            }
        }
    }

    .uploadedFile {
        .filePJ {
            .remove-file {
                margin-left: 10px;
            }
        }
    }

    .w-lines-group {
        .w-input-label span {
            color: #000;
        }
    }
    .w-text-input [name="contactNumCommande"] + .w-input-label  span::after {
        content: "";
    }
    .w-fieldset-label {
        margin: 0;
        font-size: 13px;
        margin-bottom: .8rem;
        width: 100%;
        font-family: $primaryFont;
        font-weight: 500;
        letter-spacing: 0.002em !important;
    }
    .w-lines-group {
        flex-direction: row;
        justify-content: flex-start;
    }
    .optinLightboxContact {
        display: none;
    }
    #recaptcha {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        & > div {
            transform-origin: left;
            transform: scale(0.79);
        }
    }
    .w-submit {
        position: relative;

        .w-submit-button {
            padding: 0 91px;
        }
    }

    .wrapper_img.right {
        margin-right: 10px;

        img {
            width: 86%;
            height: auto;
        }
    }
    small.w-input-error {
        margin-top: 0;
        font-size: .7rem;
    }
}

div.w-form-line > div.w-input-container:nth-child(2) {
    margin-left: 1.5rem;
}

.contact_faq .w-contact-form>:nth-child(7)  .w-input-container {
    margin-left: 1.5rem;
}

.contact_faq {
    #contact_form {
        position: relative;
        order: 2;
        width: 53%;
        .required-fields {
            bottom: 82px;
            font-size: 10px;
        }
    }
    #mes_err_fieldset + .required-fields {
        display: none;
    }
    .title_container_contact {
        display: flex;
        max-width: 1720px;
        margin: 0 auto;
        padding: 16px 0 8px 0;
        min-height: 0!important;

        .titre_contact {
            font-family: $secondaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 27px;
            text-transform: uppercase;
        }

        .wrapperContentFaqForm {
            display: block;
            width: 100%;
        }
    }

    .w-input-container+.w-input-container {
        margin-left: 0;
    }

    @media (max-width: 1720px) {
        .title_container_contact {
            padding: 0 32px;
        }

        .wrapperContentFaqForm {
            padding: 0 32px;

        }
    }

    .required-fields {
        margin-top: -60px;
    }

    .breadcrumbs {
        padding-top: 0;
    }
    .main_ariane {
        margin-bottom: 40px;
    }

    .w-contact-form {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        & > :nth-child(6) {
            width: 48.07%;
        }
        & > :nth-child(7) {
            width: 51.9%;
        }

        .w-form-line {
            .w-dropdown.w-has-error {
                border: $alert 1px solid;
            }
        }
    }

    @media screen and (min-width: 1600px) and (max-width: 1920px) {
        .w-contact-form {
            & > :nth-child(6) {
                width: 48.7% !important;
            }

            & > :nth-child(7) {
                width: 51.3% !important;
            }
        }
    }
}