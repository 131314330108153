.search_recap,
.no_result_block {
    max-width: $wrapperMediumXL;
    margin: 0 auto 16px;

    @include mediumxlScreen {
        padding: 0 $min_pad;
    }
}

.search_recap {
    padding-top: 15px;

    .recap_label {
        display: block;
        font-size: $small_txt;
        text-transform: uppercase;
        line-height: $small_txt_lh;
        color: $grey;
        margin-bottom: 8px;
    }

    .search_term {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        padding: 0 8px;

        &_container {
            position: relative;
            display: block;
            max-width: 500px;
            width: 100%;
            font-family: $secondaryFont;
            font-size: 30px;
            border-bottom: 1px solid $primaryColor;
            padding: 0 10px 10px 0;

            .close {
                position: absolute;
                top: calc(50% - 5px);
                right: 10px;
                width: 18px;
                height: 18px;
                transform: translateY(-50%);
                cursor: pointer;

                &::before {
                    content: "";
                    display: block;
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(2,6,6);
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    .glmt {
        &_bfr,
        &_aft {
            position: absolute;
        }

        &_bfr {
            top: 0;
            left: 0;
        }
        &_aft {
            top: 0;
            right: 0;
        }
    }

}
// No results **************************************************/
.no_result_block {
    .title_no_result {
        font-family: $secondaryFont;
        font-size: $small_txt;
        text-transform: uppercase;
        line-height: $small_txt_lh;
        margin: 16px 0 11px;
    }
    .search_tips_section {
        font-size: $small_txt;
        line-height: $small_txt_lh;
    }
    .tip {
        list-style-type: disc;
        list-style-position: inside;
    }
}



// Search loader **********************************************/
.fullpage-loader,
.custom_site_loader {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, .5) url(../svg/search-loader.svg) no-repeat center;

    span {
        font-size: 0;
    }
}

.custom_site_loader {
    display: none;

    &.active {
        display: block;
    }
}