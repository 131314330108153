.lightbox {
    position: fixed;
    z-index: -1;
    opacity: 0;
    background-color: $white;
    top: 50%;
    left: 50%;
    width: 80%;
    box-sizing: border-box;
    padding: 0;
    transform: translate(-50%, -50%);
    padding: 64px;
    transition: $all_transition;

    &.actif {
        z-index: 11;
        opacity: 1;
        transition: all .3s ease-in-out;
    }

    &.box_wishlist {
        max-width: 688px;
        padding: 64px 68px;

        .light {
            &_title {
                font-family: $secondaryFont;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 18px;
                margin-bottom:  24px;
            }
            &_subtitle {
                display: block;
            }
        }

        p {
            font-size: $small_txt;
            line-height: $small_txt_lh;
            margin-bottom: 32px;
        }
    }

    &#abox {
        width: fit-content;
    }


    .close_pop {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        padding: 40px;

        .bfr {
            width: 14px;
            height: 16px;
        }
    }

    // Sélection offerte
    &#mention_legales_pop_up {
        &.actif {
            display: block!important;
        }

        #fermer_mention_legales_pop_up {
            position: absolute;
            top: 0;
            right: 0;
            outline: none;
            background: transparent;
            border: 0;
            width: auto;
            height: auto;
            font-size: 0;
            padding: 40px;

            .btn_retour {
                &::after {
                    content: "";
                    display: block;
                    width: 16px;
                    height: 16px;
                    @include bgIconPath($spritesheet);
                    @include bgIconCoord(2, $col, $row);
                }
            }
        }

        .pop_mention_title {
            font-family: $secondaryFont;
            font-size: 24px;
            font-weight: $medium;
            text-transform: uppercase;
            margin-bottom: 2rem;
        }

        .pop_mention_detail {
            font-size: $small_txt;
            line-height: $small_txt_lh;
            overflow-y: auto;
            max-height: 299px;
        }
    }
}

// Sélection offerte
#lightbox_achat_express {
    @extend .lightbox;
}
#selection-offer,
#lightbox_achat_express {
    max-width: 688px;
    width: 100%;
    height: auto;
    padding: 86px 52px 40px;

    .close_pop {
        outline: none;
        background: transparent;
        border: 0;
        width: auto;
        height: auto;
        font-size: 0;

        >span {
            &::after {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                @include bgIconPath($spritesheet);
                @include bgIconCoord(2, $col, $row);
            }
        }
    }

    .selection-offer-header {
        text-align: initial;

        >p:not(.selection-offer-title) {
            margin-bottom: 32px;
            font-size: $small_txt;
            line-height: $small_txt_lh;
        }

        >p:not(.selection-offer-title) #nb_prod_selection_offerte {
            font-size: inherit;
            font-weight: inherit;
        }

        .selection-offer-title {
            font-family: $secondaryFont;
            font-size: 15px !important;
            line-height: 18px;
            font-weight: $medium !important;
            text-transform: uppercase;
            margin: 0 !important;
            margin-bottom: 24px;
        }

    }

    .selection-offer-content {
        width: unset;
        max-height: 415px;
        margin: 0;
    }

    #wrap_lion {
        width: auto;

        .js-form-selection-offer {
            min-height: unset;
            max-width: 282px;
            width: 100%;
            padding: 16px;
        }

        .titleOfferedProd {
            font-size: 16px;
            line-height: 19px;
            margin: 16px 0;

            >span {
                display: none;
            }
        }

        // Color and Size swipers
        .color_list {
            &:not(.swiper-container-initialized) {

                .swiper-slide {
                    width: 23px;
                    margin-right: 10px;
                }

                .prevColour {
                    &::before {
                        opacity: .2;
                    }
                }

                @media screen and (max-width: 1100px) {

                    .swiper-slide {
                        margin-right: 16px;
                    }
                }

                input[type=radio]+label {
                    border-radius: 0;
                    border: 1px solid $primaryColor;
                    transition: box-shadow .3s ease;
                }

                input[type=radio]:checked+label,
                input[type=radio]+label:hover {
                    box-shadow: inset 0 0 0 1px $primaryColor;
                }

                .nextColour,
                .prevColour {
                    &.swiper-button-disabled {
                        opacity: 1;

                        &::before {
                            opacity: .2;
                        }
                    }
                }
            }
        }

        .size_list {
            padding: 0;

            .prevSize,
            .nextSize {
                display: none;
            }

            input[type=radio]+label {
                width: auto;
                margin-right: 8px;

                &::after {
                    bottom: 0;
                    right: 0;
                    width: 0;
                }

                &:hover {
                    &::after {
                        width: 100%;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

#exit_overlay_OVI {
    max-width: 1046px;
    min-height: 500px;
    display: flex;
    align-items: center;
    padding: 0;

    .close_pop {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        padding: 40px;

        .bfr {
            width: 14px;
            height: 16px;
        }
    }

    .wrapper_newspopup_home {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 0 2rem;
    }

    >.pop_contents {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        .pop_contents {

            // Newsletter title
            >.newsletter {
                font-family: $secondaryFont;
                font-size: 15px;
                text-transform: uppercase;
                line-height: 18px;
                margin-bottom: 24px;
            }
        }
    }
    .pop_cgv_listing {
        font-size: 10px;
        margin-top: 16px;
    }

    .content_newspopup {
        max-width: 266px;
        width: 100%;

        .w-form-line-container {
            position: relative;
        }

        .w-form-line {
            &:first-child {
                margin-bottom: 24px;
            }

            &:nth-child(2) {
                position: absolute;
                top: 26px;
            }
        }
    }

    .w-email-input {
        font-family: $primaryFont;

        &.w-nonempty .w-input-label {
            top: 0;
            transform: translateY(-100%);

            span {
                font-size: 10px;
                font-style: italic;
            }
        }

        .w-input-element {
            font-family: $primaryFont;
            font-size: 15px;
            height: auto;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid $primaryColor;
            padding: 0 0 4px;

            &:focus {
                outline: none;
                border: 0;
                box-shadow: none;
                border-bottom: 1px solid $primaryColor;
            }
        }

        .w-input-label {
            left: 0;
            color: $primaryColor;
        }
    }

    .gender {
        margin-bottom: 32px;

        .w-input-label {
            font-size: 14px;
            line-height: 21px;
            color: $primaryColor;
        }

        .w-radio-input .w-input-element:checked+.w-input-label::before {
            background-color: $primaryColor;
            border: 1px solid $darkGrey;
        }
    }

    // Lightbox img
    .wrapper_img {
        position: relative;
        display: block;
        max-width: 460px;
        width: 40%;

        img {
            width: 100%;
            height: auto;
        }
    }
    #img_lightbox {
        max-width: 100%;
        vertical-align: bottom;
    }

    // Submit & btns
    .w-submit {
        max-width: unset;

        .w-submit-button {
            &.w-loader {
                background: $white url(../svg/three_dots.svg) center no-repeat;
                background-size: 20%;

                >span {
                    display: none;
                }
            }

            >span {
                font-family: $secondaryFont;
                background-color: unset;
                color: unset;
                border-color: unset;
                font-weight: $medium;
            }
        }
    }


    // Errors
    .w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
    .w-has-error:not(.w-dropdown) input[type=email].w-input-element {
        box-shadow: none;
        border-bottom: 1px solid $red;
    }
    .w-input-note {
        font-size: .68rem;
        margin: 0;
    }

    // Confirmation inscription
    #conf_inscription_news {
        .title_popup_inscription_home {
            font-family: $secondaryFont;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
        .detail {
            margin-bottom: 16px;
        }
    }
}

#cookiesLightbox {
    #js_cookie_refuse {
        @extend .light;
    }
    #js_cookie_accept {
        @extend .dark;
    }
}

/**
 * Main cookies acceptance (Bottom site lightbox)
 */
#cookiesLightbox {
    font-family: $primaryFont;
    font-size: $small_txt;
    padding: 40px 65px 50px;

    .cookiesLightbox_container {
        display: grid;
        margin: auto;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(4, auto);
        grid-column-gap: 13%;

        .cookiesLightbox_content {
            grid-column: 1;
            grid-row: 1 / 3;
            max-width: none;
        }
        
        .cookiesLightbox_preferences {
            grid-column: 1;
            grid-row: 4;
            margin: 0;

            #cookiePref02,
            #cookiePref03 {
                .icon {
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    top: -3px;
                }

                .w-checkbox-input {
                    .w-input-element+.w-input-label::after {
                        top: 0;
                    }
                }
            }
        }

        .cookiesLightbox_custom_btn {
            grid-column: 1;
            grid-row: 3;
            margin-left: 0;
            
            .w-submit-button {
                border: none;
                text-transform: none;
                font-weight: 700;
                font-size: 13px;
                margin-top: 0;
                width: 210px;

                span {
                    background-color: $white;
                    color: $black;
                    justify-content: flex-start;
                    text-transform: uppercase;
                    font-size: 13px;
                    font-family: $primaryFont;
                }

                &:hover {
                    background-color: transparent;
                    color: $black;
                }

                &.w-loader {
                    display: none;
                }

                &::after {
                    position: absolute;
                    right: 24px;
                }

                &.opened::after {
                    display: none;
                }
            }
        }

        .cookiesLightbox_refuse_btn {
            grid-column: 2;
            grid-row: 2;
            margin-left: 0;
            margin-top: 1rem;
            width: 206px;
        }

        .cookiesLightbox_accept_btn {
            grid-column: 2;
            grid-row: 1;
            margin-top: 1rem;
            width: 206px;
        }
    }

    h3 {
        font-weight: 500;
        line-height: 15px;
    }

    h3,
    section:last-of-type {
        font-family: $secondaryFont;
    }

    section,
    .w-form-line.w-submit {
        max-width: $wrapperMediumXL;
        width: 100%;
    }

    section {
        &:first-of-type {
            text-transform: uppercase;
            line-height: 18px;
        }

        &:last-of-type {
            text-transform: uppercase;
            line-height: 15px;
            margin-top: 40px;
        }
    }

    a {
        color: $primaryColor;
    }

    .w-form-line.w-submit {
        flex-direction: column-reverse;
        align-items: flex-end;
        height: auto;
        position: absolute;
        top: 32px;
        right: 5.208vw;

        .w-btn-wrapper {
            position: absolute;
            z-index: 3;
            width: 12.500vw;
            max-width: 240px;
            right: 0;

            &:first-child {
                margin-top: 56px;
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }

    .w-checkbox-input .w-input-element+.w-input-label span.multiline,
    .w-radio-input .w-input-element+.w-input-label span.multiline {
        color: $primaryColor;
        line-height: 1;

        i {
            font-style: normal;
        }
    }

    .toggleCookiePrefs {
        text-decoration: none;
    }

    .cookieBlocInfo p, .cookieBlocInfo  li {
        font-family: $primaryFont;
        font-weight: $medium;
        font-size: 13px;
        line-height: 16px;
        color: $primaryColor;
        text-transform: none;
    }
}

// Zoom Product
#zoomBox {
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: $white;
    padding: 0 60px;
    display: none;

    @include largeScreen {
        padding: 0 pxToVw(60, 1920);
    }

    .close_pop {
        padding: 27px 1.458vw;
        z-index: 3;
    }

    .zoom_main_wrapper {
        position: relative;
        width: 100%;
        margin: auto;

        .zoomPrev,
        .zoomNext {
            position: absolute;
            top: 0;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 16px;
            cursor: pointer;
            z-index: 2;

            &.swiper-button-disabled {
                opacity: 0.35;
                cursor: default;
            }
        }

        .zoomPrev {
            left: -3%;

            .bfr::before {
                transform: rotate(90deg);
            }
        }

        .zoomNext {
            right: -3%;

            .bfr::before {
                transform: rotate(-90deg);
            }
        }
    }

    #zoom_box_wrapper {
        position: relative;
        max-width: 1780px;
        width: 100%;
        margin: 0 auto;


        .productBigPictures {
            margin-bottom: 150px;
        }
    }

    img.img_large {
        width: 100%;
        height: auto;
    }

    &.actif {
        display: flex;
    }
}

// DISPO MAG
#availabilityInStore {
    max-width: 688px;
    max-height: 655px;

    .close_pop {
        top: 40px;
        right: 41px;
        content: "";
        display: block;
        width: 16px;
        height: 16px;
        @include bgIconPath($spritesheet);
        @include bgIconCoord(2, $col, $row);
        font-size: 0;
        background-color: $white;
        border: 0;
        padding: 0;
        outline: none;
    }

    .availabilityInStoreTitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.5rem;

        &::before {
            display: none;
        }
    }

    .availabilityInStoreTitle,
    .availabilityInStoreSubtitle {
        font-family: $secondaryFont;
        font-size: 15px;
        font-weight: $medium;
        text-transform: uppercase;
        line-height: 18px;
    }
    .availabilityInStoreSubtitle {
        margin-bottom: 24px;
        display: none;
    }

    .availabilityInStoreDescription {
        font-size: $small_txt;
        line-height: $small_txt_lh;
    }

    .field_address {
        margin-top: 24px;

        #searchAddress {
            width: calc(100% - 32px);
            font-size: 14px;
            line-height: 19px;
            padding: 14px 16px 13px;
            border-radius: 0;
            border: 1px solid $grey;
        }

        button {
            display: none;
        }
    }

    // Liste des magasins
    #storeList {
        margin-top: 40px;
    }
    #storeListScrollWrapper {
        max-height: 300px;
    }

    .elem_liste_distributeur {
        max-width: 95%;
        padding-bottom: 24px;
        margin-bottom: 25px;
        border-bottom: 1px solid $primaryColor;

        .see_map,
        .store_phone {
            display: none;
        }

        .store_name_wrapper {
            display: flex;
            justify-content: space-between;

            .storeStock {
                font-family: $primaryFont;
                text-transform: initial;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    margin-right: 4px;
                    border-radius: 50%;
                }
                &.low::before {
                    background-color: #D54B43;
                }
                &.medium::before {
                    background-color: #FF9900;
                }
                &.high::before {
                    background-color: #04AC6C;
                }
            }
        }

        .store_name {
            font-family: $secondaryFont;
            font-weight: $medium;
            font-size: $small_txt;
            line-height: $small_txt_lh;
            text-transform: uppercase;
            color: $primaryColor;
            margin-bottom: 10px;
        }

        .store_address {
            display: flex;
            font-family: $primaryFont;
            font-weight: $medium;
            font-size: 13px;
            line-height: 26px;
            text-transform: lowercase;
            color: $primaryColor;

            > span {
                text-transform: capitalize;
                margin-left: 5px;
            }
        }

        .store_open_hours {
            display: flex!important;
            align-items: center;
            flex-wrap: wrap;
            font-size: $small_txt;
            line-height: $small_txt_lh;
            margin-bottom: 8px;


            .txt_store_open,
            .txt_store_closed {
                display: flex;
                align-items: center;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    margin-right: 5px;
                    border-radius: 50%;
                }
            }

            .txt_store {
                &_open {
                    color: #04AC6C;
                    &::before {
                        background-color: #04AC6C;
                    }
                    &+span {
                        text-transform: lowercase;
                        margin-left: 5px;
                    }
                }
                &_closed {
                    color: #D54B43;
                    &::before {
                        background-color: #D54B43;
                    }
                }
            }
        }

        .show_schedule {
            cursor: pointer;
            display: inline-block;
            font-family: $secondaryFont;
            font-weight: $medium;
            font-size: 11px;
            line-height: 13px;
            padding-bottom: 3px;
            border-bottom: solid 1px black;
        }

        // Will be displayed in JS
        .store_week_schedule {
            display: none;
            font-size: 11px;
            line-height: 13px;
            margin-top: 4px;
        }
    }
}

// PDV BOX
#pdvBox {
    display: none;

    &.actif {
        display: block;
    }
}

// MODBOX
#modboxpromo {
    max-width: 600px;

    #btn_cmd_valid {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modboxBtnClose {
        position: absolute;
        top: 0;
        right: 0;
        display: block !important;
        width: auto;
        height: auto;
        font-size: 0;
        background: transparent;
        border: 0;
        padding: 0;
        outline: none;
        padding: 16px 32px;

            &::after {
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                @include bgIconPath($spritesheet);
                @include bgIconCoord(2, $col, $row);
            }
    }
}

.wishlist {
    #sendwishlistbox {
        #sendfriend_form_buttons {
            margin-top: 18px;
        }
    }
}