body {
    font-family: $primaryFont;
    color: $primaryColor;
}

/* Vue JS */
[v-cloak] {
    display: none;
}

.pricetag {
    position: relative;
    .no_cents {
        display: none;
    }
    &.crossed {
        color: $grey;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $grey;
        }
    }
}

.no_cents {
    display: none;
}

.cache,
[v-cloak] {
    display: none;
}

.swiper-button {
    &-prev,
    &-next {
        width: auto;
        background: none;
        z-index: 2;
        margin-top: unset;

        &::before {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            @include bgIconPath('../svg/spritesheet.svg');
            @include bgIconCoord(1, 6, 6);
        }

        &::after {
            content: none;
            display: none;
        }
    }

    &-prev {
        left: 0;
        right: unset;
        &::before {
            transform: rotate(90deg);
        }
    }
    &-next {
        right: 0;
        left: unset;
        &::before {
            transform: rotate(-90deg);
        }
    }
}

.page_wrapper {
    max-width: $wrapperLarge;
    width: 100%;
    margin: 0 auto;
}

.shad {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    overflow-y: auto;

    &.actif,
    &.search_active {
        z-index: 10;
        opacity: 1;
        cursor: pointer;

        &#shad_menu,
        &#shad_filter,
        &#shad_search {
            z-index: 8;
        }

        &#shad_filter {
            position: fixed;
            z-index: 11;
            top: 0;
            left: 0;
        }
    }
}

.breadcrumbs {
    display: flex;
    max-width: 1720px;
    margin: 0 auto;
    padding-top: 16px;

    .breadcrumb {
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        &:last-child {
            font-family: $primaryFont;
        }
        &:not(:last-child)::after{
            content: "/";
            font-family: $secondaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            padding: 0 4px;
        }
    }
}

@media (max-width: 1720px) {
    .breadcrumbs {
        padding: 0 32px;
    }
}

/**
 * Swiper
 */
.swiper-lazy-preloader {
    display: block;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    margin-left: 0;
    margin-top: 0;
    z-index: 10;
    background-color: #fff;
    transform-origin: none !important;
    animation: none !important;
    border: 0;
    border-radius: 0;

    &:after {
        display: none;
    }

    .ill_img {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    &.searchLazy {
        padding-top: 100%;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

/**
 * iFrames
 */
iframe {
    border: 0;
}


// Product's common size & color style (Rayon + FP)
.itm_form_group,
.ligne_form {

    .form_itm {
        &.color {
            input[type="radio"] {
                display: none;

                &+label {
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    position: relative;
                    box-sizing: border-box;
                    border: 1px solid $primaryColor;
                    transition: all .3s ease-in-out;
                    border-radius: 50%;
                }

                &:checked {
                    &+label {
                        box-shadow: inset 0 0 0 1px $primaryColor;
                    }
                }

            }

            &:hover {
                input[type="radio"] {
                    &+label {
                        box-shadow: inset 0 0 0 1px $primaryColor;
                    }
                }
            }
        }

        &.size {
            margin-right: 8px;

            input[type="radio"] {
                display: none;

                &+label {
                    display: block;
                    cursor: pointer;
                    position: relative;
                    box-sizing: border-box;
                    padding-bottom: 5px;

                    &:before {
                        z-index: 20;
                        content: "";
                        position: absolute;
                        bottom: 0;
                        height: 1px;
                        right: 0;
                        width: 0;
                        background-color: $primaryColor;
                        transition: $all_transition;
                    }
                }

                &.disabled {
                    &+label {
                        color: $grey;

                        &::before {
                            background-color: $grey;
                        }

                        &:after {
                            content: "";
                            height: 1px;
                            width: 100%;
                            background-color: $grey;
                            display: block;
                            position: absolute;
                            top: 40%;
                            left: 50%;
                            margin: auto;
                            transform: translate(-50%, -50%);
                        }

                        &[title="Blanc"],
                        &[title="transparent"] {
                            &:after {
                                background-color: rgba($black, 0.15);
                            }
                        }
                    }
                }

                &:checked {
                    &+label {
                        font-weight: $bold;

                        &:before {
                            right: auto;
                            left: 0;
                            width: 100%;
                        }
                    }
                }
            }

            &:hover {
                input[type="radio"] {
                    &+label {
                        &:before {
                            right: auto;
                            left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

}

.date_select select,
.w-oneclick-connect .w-social-btn span {
    color: $primaryColor;
}

// Charte de nomage => rectification css
a.entry.underline_target.has_submenu.hoverable, 
.home-module-title.title_2 h3, 
#step-01.on h1, 
#step-02.on h1, 
#step-03.on h1, 
#step-04.on h1, 
#step-05.on h1 {
    font-weight: $medium;
}

// Mail alert stock prevent background from user agent style
#mail_alerte_stock {
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent !important;
}

// Fix image display when NO_LAZYLOAD set at true
.img_no_lazy {
    max-width: 100%;
}

/* masquer le template de recherche au chargement des pages */
[v-cloak] {
    display: none;
}

// SLIDER NATIVE JS

.ui-slider {
    position: relative;
    text-align: left;
    z-index: 0;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
}

.ui-slider-horizontal {
    height: 3px;
}

#satisfactionForm .ui-slider-horizontal .ui-slider-handle {
    margin-top: auto;
    top: -3px;
    background: none;
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 2px;
    margin: 0;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.ui-slider-vertical {
    width: 0.8em;
    height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0;
}

.filter_wrapper .rng_slider .ui-widget-header {
    background-color: $black;
    border-radius: 0;
}

.filter_wrapper .rng_slider .ui-slider-handle {
    top: -4px;
    width: 10px;
    height: 10px;
    background-color: $black;
    border: 0px;
    outline: none;

    .handler_price {
        position: absolute;
        bottom:calc(-5px - 12px);
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        color: $black;
        
        &::after {
            content: "€";
        }
    }
}

.filter_wrapper .rng_slider .ui-slider-handle:active,
.filter_wrapper .rng_slider .ui-slider-handle:hover {
    border-color: $black;
}

// SLIDER VUEJS
.vue-slider {
    height: 2px;

    .vue-slider-rail {
        background-color: $grey;
        height: 2px;
    }

    .vue-slider-process {
        background-color: $black;
    }

    .vue-slider-dot {
        background-color: $black;
        border-radius: 0px;
        &:hover {
            background-color: $black;
        }

        .vue-slider-dot-tooltip-text::after {
            content: "€";
        }
    }
}
