$white              : #FFFFFF;
$black              : #000000;

$darkGrey           : #B1B1B1;
$grey               : #898989;
$red                : #D54B43;
$alert              : $red;
$green              : #04AC6C;

$primaryColor   : $black;
$secondaryColor : $white;
$tertiaryColor  : $grey;