#copyright {
    position: relative;
    max-width: $wrapperMediumXL;
    width: 100%;
    margin: 100px auto 0;

    @include mediumxlScreen {
        padding: 0 2rem;
    }

    .mention_legales_popup {
        font-size: $small_txt;
        padding: 0 0 2rem;
    }

    .wrapper_bottom_container {
        border-top: 1px solid $primaryColor;
        border-bottom: 1px solid $primaryColor;
    }
    .wrapper_bottom {
        display: flex;
        font-size: $small_txt;
        margin: 64px 0;
    }

    .wrapper_links {
        display: flex;

        >.wrap_links {
            &:not(:last-child) {
                margin-right: 290px;

                @include largeScreen {
                    margin-right: 15.104vw;
                }

                @media screen and (max-width: 1300px) {
                    margin-right: 7vw;
                }
            }
        }

        .col_title {
            font-family: $secondaryFont;
        }

        .col_content {
            > a {
                display: block;
            }
        }

        .col_title,
        .col_content>a:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    // Footer Newsletter
    .footer_newsletter_title {
        font-family: $secondaryFont;
        text-transform: uppercase;
        line-height: 15px;
        margin-bottom: 16px;
    }

    .wrapper_newsletter {
        margin-right: 367px;

        .w-email-input {
            font-family: $primaryFont;

            &::after {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                display: block;
                height: 1px;
                width: 100%;
                background-color: $primaryColor;
            }

            &.w-nonempty .w-input-label {
                font-style: italic;
                font-size: 11px;
                transform: translateY(-200%);
            }

            &.w-has-error {

                &::after {
                    background-color: $red;
                }
                .w-input-element {
                    border: 0;
                    box-shadow: none;
                }
            }

            .w-input-element {
                position: relative;
                font-family: $primaryFont;
                font-size: $small_txt;
                height: auto;
                border: 0;
                border-radius: 0;
                padding: 0;

                &:focus {
                    outline: none;
                    border: 0;
                    box-shadow: none;
                }
            }

            .w-input-label {
                font-family: $primaryFont;
                font-size: $small_txt;
                left: 0;
                color: $primaryColor;
            }
        }

        .w-input-label {
            text-transform: uppercase;
        }

        .w-submit,
        .w-submit-button {
            width: auto;
            height: 13px;
            border: 0;
            background: transparent;
            color: $primaryColor;
            font-family: $secondaryFont;
            padding: 0;

            span {
                background: transparent;
                color: $primaryColor;
            }
        }

        .w-submit {
            top: unset;
            bottom: 0;
        }

        .w-form-line {
            margin-bottom: 0;
        }

        @include largeScreen {
            margin-right: 19.115vw;
        }
        @media screen and (max-width: 1300px) {
            margin-right: 15vw;
        }
    }

    .wrapper_copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        .copyright {
            font-size: 10px;
            color: $grey;
            line-height: 12px;
            margin: 15px 0 24px;
        }
    }
}