// Global
$fp_margin : 24px;

// Global wrapper
.product_main_wrapper {
    margin-top: $fp_margin;
}

// Top Product (images + description) wrapper
.prod_top_wrapper {
    display: flex;
    max-width: $wrapperMediumXL;
    margin: 0 auto;

    @include mediumxlScreen {
        padding: 0 $min_pad;
    }
}

// Product images
.product_left_image {
    position: relative;
    max-width: 1136px;
    width: 66%;
}
.eclat_wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: 564px;
    width: 49.7%;
    z-index: 2;

    >div {
        &:not(:last-child) {
            margin-right: 8px;
        }

        img {
            max-width: 100%;
            vertical-align: bottom;
        }
    }
}

// Product pictures
.propductPicturesWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

// One single pic
.propductPicturesWrapper .has_video,
.main_product_img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 564px;
    width: 49.7%;
    margin-bottom: .417vw;
    cursor: pointer;
}

.propductPicturesWrapper .block_vid,
.main_product_img .ill_img {
    width: 100%;
    padding-top: 100%;
}

.propductPicturesWrapper {
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.main_product_img {
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }
}


// Product right, description + infos
.product_info_wrapper {
    position: relative;
    max-width: 584px;
    width: 34%;
    padding-left: 4.651vw;
    padding-top: 2.083vw;

    // Product title
    #itm_name {
        max-width: calc(100% - 40px);
        font-family: $secondaryFont;
        font-weight: $medium;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: pxToVw(14,1920);
    }

    // Wishlist Button
    #addToWishlistButtonContainer {
        position: initial;
    }
    #addToWishlistButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 0 16px 16px;

        .bgSprite {
            width: 24px;
            height: 24px;
            &::before {
                width: 100%;
                height: 100%;
            }
        }
    }

    // Prices
    .item_price {
        display: flex;
        align-items: flex-end;
        margin-bottom: pxToVw(24, 1920);

        .pricetag {
            font-size: 20px;
            line-height: 24px;

            &.crossed {
                font-size: 18px;
                line-height: 22px;
                margin-right: 8px;

                &::after {
                    top: 55%;
                }
            }
        }
    }

    // Colors
    #ligne_couleur {
        position: relative;
        max-width: pxToVw(461, 1920);
        width: 94%;
        margin-bottom: pxToVw(24,1920);

        &.with_swiper {
            padding: 0 24px;
        }

        #colorsSwiperFp {
            &:not(.swiper-container-initialized) {
                .choices_list {
                    display: flex;
                    max-width: 100%;
                }
                .form_itm.color {
                    margin-right: 8px;
                }
            }
        }

        .fp_swiper_button {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 50%;
            background-color: $white;
            transform: translateY(-50%);

            &::before {
                width: 100%;
                height: 100%;
            }

            &.swiper-button {
                &-prev {
                    left: 0;
                }
                &-next {
                    right: 0;
                }
            }
        }
    }


    // Sizes
    #ligne_pointure {
        position: relative;
    }
    .sizesList {
        display: flex;
        margin-bottom: pxToVw(40, 1920);
    }

    // Form submits
    #product_addcart {
        margin-bottom: pxToVw(24, 1920);
    }
    #bloc_add_basket { // ADD TO CART

        &.inactiv {

            #btn_add_cart {
                opacity: .5;
                transition: none;

                &:hover {
                    font-family: $secondaryFont;
                    background-color: $primaryColor;
                    color: $white;
                    border: 0;

                    span {
                        background-color: $primaryColor;
                        border: 0;
                        color: $white;
                    }
                }
            }
        }
    }
    #bloc_availability { // DISPO MAG
        &.inactiv {
           opacity: 0;

            #ctaAvailabilityInStore:hover {
                font-family: $primaryFont;
                background-color: $secondaryColor;
                color: $primaryColor;

                span {
                    background-color: $secondaryColor;
                    color: $primaryColor;
                }
            }
        }
    }

    // Alert stock confirmation : Alert stock form style in _forms.scss
    .bloc_add_alert_confirmation {
        font-size: $small_txt;
        margin-bottom: pxToVw(10, 1920);

        >.w-submit-button {
            @extend .light;
        }
    }

    .prod_btn_wrapper {
        >.form_submit {
            &:not(:last-child) {
                margin-bottom: pxToVw(16,1920);
            }
        }
    }


    .product_description {
        display: block;
        font-size: $small_txt;
        text-transform: lowercase;
        line-height: $small_txt_lh;
        margin-bottom: pxToVw(10, 1920);

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
// Sticky description bloc
.pos_sticky {
    position: sticky;
    top: calc(#{$headerHeightSmall} + 40px);

    & > span {
        display: none;
    }
}

#reassurance_delivery_fp {
    display: block;
    margin-top: 1.25vw;
    font-family: $secondaryFont;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
}

// Thumbs description and informations
.thumbnav {
    margin-top: 40px;

    .thumb {
        border-bottom: 1px solid $primaryColor;

        &:first-child {
            border-top: 1px solid $primaryColor;
        }

        &lnk {
            position: relative;
            display: block;
            font-size: 16px;
            text-transform: uppercase;
            line-height: 19px;
            cursor: pointer;
            padding: 24px 0;

            &.active {
                .bgSprite {
                    &::before {
                        transform: rotate(180deg);
                    }
                }
            }

            span {
                font-weight: $medium;
            }

            .bgSprite {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);

                &::before {
                    transition: $all_transition;
                }
            }
        }

        &_content {
            display: none;
            font-size: $small_txt;
            padding-bottom: 24px;

            &.opened {
                margin-top: 15px;
            }

            .chez_vous_entre::first-letter {
                text-transform: uppercase;
            }
        }
    }


    @include largeScreen {
        margin-top: pxToVw(40, 1920);

        .thumb {
            &lnk {
                padding: pxToVw(24, 1920) 0;
            }

            &_content {
                padding-bottom: pxToVw(24, 1920);

                &.opened {
                    margin-top: pxToVw(15, 1920);
                }
            }
        }
    }
}


.product_associations_wrapper {
    position: relative;
    max-width: 1800px;
    margin: pxToVw(100,1920) auto 0;
    padding: 0 40px;

    @media screen and (max-width: 1800px) {
        width: 96%;
    }

    .title_btn_wrapper {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: $medium;
        line-height: 24px;
        margin-bottom: pxToVw(28, 1920);
    }

    .gondole_complete_btn {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        width:  24px;
        height: 100%;
        transform: translateY(-50%);
    }
    .swiper-button {
        &-prev {
            left: 0;
        }
        &-next {
            right: 0;
        }
    }
}

#gondole_complete {

    .item {
        max-width: 412px;
    }
}

// Product size tooltip
.disabledBtnTooltip {
    &.actif {
        display: block !important;
        bottom: -#{pxToVw(16,1920)}!important;

        @include smallScreen {
            bottom: -#{pxToVw(24,1920)} !important;
        }
    }
}

.product_page {

    .eclat_wrapper>div:not(:last-child) {
        margin-right: 0;
    }

    .eclat_rond {
        position: absolute;
        right: 0;
    }

    .eclat_rect {
        position: absolute;
        right: 0;
    }
}