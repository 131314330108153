/* PRODUCT ITEM */
.item.wrapperProdVertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;

    /* Display rollover produit block */
    &:not(.no_stock):hover {
        .wrap_rolloverproduit {
            pointer-events: initial;
            opacity: 1;
        }

        .rollover_rayonsNext,
        .rollover_rayonsPrev {
            pointer-events: initial;
            opacity: 1;

            &.swiper-button-disabled {
                opacity: .35;
            }
        }

        .rolloverImg {
            opacity: 1;
        }
    }

    .noStock {
        &_redirect_container {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba($color: $secondaryColor, $alpha: .4);
            z-index: 4;

            .link_redirect {
                display: block;
                height: 100%;
            }
        }

        &_txt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 200px;
            width: 90%;
            height: 40px;
            border: 1px solid black;
            font-size: 12px;
            text-transform: uppercase;

            @media screen and (max-width:1024px) {
                font-size: 9px;
                width: 66%;
                padding:0.5vw;
                height:25px;
            }
        }
    }

    .wrap_rolloverproduit {
        pointer-events: none;
        position: absolute;
        bottom: 24px;
        left: 50%;
        z-index: 3;
        opacity: 0;
        display: flex;
        align-items: flex-end;
        max-width: 509px;
        width: 91.4%;
        min-height: 88px;
        transform: translateX(-50%);
        background-color: $white;
        padding: 24px;
        transition: $all_transition;

        .wrap_rolloverproduit_container {
            width: 100%;
        }

        .productErrorFieldset {
            font-size: $small_txt;
            color: $red;
            margin: 0 0 7px;
            transform: translateY(-75%);

            @media screen and (max-width: 1550px) {
                margin-bottom: 1rem;
            }
        }

        .titre_choices_list {
            display: none;
        }

        /* color swiper */
        .swiper {
            &-container-initialized {
                padding: 0 1.5rem;
            }

            /* Style when not initialized */
            &-container:not(.swiper-container-initialized) {
                z-index: 3;
                .swiper-slide {
                    width: auto;
                    margin-right: 8px;
                }

                .swiper-button {
                    &-prev,
                    &-next {
                        display: none;
                    }
                }
            }

            &-button {
                &-prev,
                &-next {
                    top: unset;
                    bottom: 0;
                    background-color: $white;

                    &.swiper-button-disabled {
                        opacity: 1;

                        &::before {
                            opacity: .35;
                        }
                    }
                }

                &-prev {
                    left: -1px;
                }
                &-next {
                    right: -1px;
                }
            }
        }
    }

    .rolloverImg {
        opacity: 0;
        transition: $all_transition;
    }

    .productColorFieldset {
        margin-bottom: 32px;
        position: relative;

        /* DO NOT REMOVE PLEASE -- Custom white blocks to prevent color swiper slides to be visible behind swiper buttons */
        &::before,
        &::after {
            position: absolute;
            top: 0;
            content: "";
            display: block;
            width: 3px;
            height: 100%;
            background-color: $white;
            z-index: 3;
        }
        &::before {
            left: 0;
            transform: translateX(-50%);
        }

        &::after {
            right: 0;
            transform: translateX(50%);
        }
    }
    .count_colors {
        display: none;
    }

    .productSizeFieldset {
        max-width: 47%;

        .choices_list {
            display: flex;
            flex-wrap: wrap;
        }
    }

    /* Block image + achat express */
    .imgWrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .container_eclats {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        max-width: calc(100% - 55px);

        .eclat_img {
            &:not(:last-child) {
                margin-right: 8px;
            }
        }

        img {
            vertical-align: bottom;
        }
    }

    .wishlistBtn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 24px 24px 16px 16px;
        z-index: 2;
        cursor: pointer;

        .wishlist::before {
            width: 16px;
            height: 16px;
        }
    }

    .blockInfosProd {
        display: block;
        max-width: 100%;
        width: 100%;
        background: $white;

        .item_name {
            display: block;
            font-size: 12px;
            line-height: 19px;
            font-weight: $medium;
            font-family: $secondaryFont;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 12px 0px 8px;
        }
    }

    .wrapper_description{
        display: flex;
        align-items: baseline;
    }

    .wrapper_price {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .pricetag {
            font-size: 12px;
            line-height: $small_txt_lh;

            &.crossed {
                position: relative;
                font-size: 12px;
                line-height: 14px;
                color: $grey;
                margin-right: 8px;

                &::after {
                    bottom: 7px;
                }
            }

            .no_cents {
                display: none;
            }
        }
    }

    .bloc_add_color,
    .expressAlertStock {
        position: absolute;
        bottom: 24px;
        right: 24px;
    }
    .bloc_add_color {
        max-width: 207px;
        width: 45%;

        [name="load_product"] {
            display: none;
        }
    }
    .expressAlertStock {
        max-width: 230px;
        width: 45%;
        font-size: 11px;

        .alert_stock {
            margin-bottom: 4px;
        }

        .w-input-element:not([type=radio]):not([type=checkbox])[name="mail_alerte_stock"] {
            outline: none;
            font-family: $primaryFont;
            font-size: 12px;
            height: unset;
            border: 0;
            padding-bottom: 4px;
            padding-right: 20px;
            padding-left: 0;
            border-bottom: 1px solid $primaryColor;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::placeholder {
                text-transform: uppercase;
            }

            &.inputErr {
                border-bottom: 1px solid $red;
            }
        }

        .bloc_add_alert_form>.w-form-line {
            position: relative;
        }
        .form_submit {
            position: absolute;
            bottom: 4px;
            right: 0;

            button {
                font-family: $secondaryFont;
                text-transform: uppercase;
                appearance: none;
                width: auto;
                height: auto;
                border: 0;
                background: 0 0;
                color: $primaryColor;
                padding: 0;
                cursor: pointer;
            }
        }

        .alert_return {
            /* Keep it hidden */
            display: none !important;
            position: absolute;
            font-size: 11px;
            top: 0;
            cursor: pointer;
            right: 0;
        }
    }
}


/* PRODUCT ITEM IMAGE */
a.block_lnk {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 100%;

    img.imgProd {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        vertical-align: top;
        /* Avoid alt attribute to be displayed */
        font-size: 0;
    }
}

/* PRODUCT SWIPER */
.productSwiper {
    &:not(.swiper-container-initialized) {
        .swiper-button-prev {
            &::before {
                opacity: .35;
            }
        }
    }
    .rollover_rayonsPrev,
    .rollover_rayonsNext {
        pointer-events: none;
        top: 0;
        transform: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 100%;
        opacity: 0;
        transition: $all_transition;

        &::before {
            width: 16px;
            height: 16px;
        }
    }

    img.swiper-lazy {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .swiper-slide:not(:first-child) .ill_img > img {
        animation-duration: 0.25s;
    }

    .wrap_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 100%;

        .link_product_iframe {
            display: block;
            z-index: 2;
        }

        .link_product_iframe,
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
